import React, { useState } from 'react';

import {
  EditLabelBackArrow,
  EditLabelColorSelectTick,
  EditLabelCreateNewColorHeader,
  EditLabelCreateNewColorSelect,
  EditLabelCreateNewCreateButton,
  EditLabelCreateNewText,
  EditLabelCreateNewTextBox,
} from './styled/EditLabel.styled';
import { useDispatch } from 'react-redux';
import { updateLabel } from '../../features/labels/labelsSlice';
import useAuth from '../../features/auth/hooks/useAuth';

const EditLabel = ({ setShowEditLabel, showEditLabel, label }) => {
  const types = [
    { color: '#89E18D' },
    { color: '#E2A161' },
    { color: '#4674B1' },
    { color: '#7BC6B1' },
    { color: '#EFB5B4;' },
    { color: '#ECE690' },
  ];
  const [selectedColor, setSelectedColor] = useState(label.color);
  const [labelName, setLabelName] = useState(label.title);
  const auth = useAuth();
  const dispatch = useDispatch();
  const onClickLabelColor = (e, color) => {
    e.preventDefault();
    if (selectedColor !== color) {
      setSelectedColor(color);
    } else {
      setSelectedColor('');
    }
    e.stopPropagation();
  };
  const onClickUpdateLabel = () => {
    if (labelName && selectedColor) {
      const updatedLabel = { ...label, userId: auth.getUser()._id, title: labelName, color: selectedColor };
      dispatch(updateLabel(updatedLabel));
    } else {
      // console.log('enter label name and Select Color');
    }
  };
  return (
    <>
      <div style={{ display: 'flex', alignItems: 'baseline', marginBottom: '9px', marginTop: '12px' }}>
        <EditLabelBackArrow onClick={() => setShowEditLabel(false)} />
        <EditLabelCreateNewText>Update label</EditLabelCreateNewText>
      </div>
      <EditLabelCreateNewTextBox
        value={labelName}
        onChange={e => setLabelName(e.target.value)}
        placeholder="Write a label name"
      />
      <EditLabelCreateNewColorHeader>Colour</EditLabelCreateNewColorHeader>
      <div style={{ display: 'grid', gap: '10px 10px', gridTemplateColumns: 'auto auto auto' }}>
        {types.map(type => (
          <>
            <EditLabelCreateNewColorSelect color={type.color} onClick={e => onClickLabelColor(e, type.color)}>
              {selectedColor === type.color && <EditLabelColorSelectTick />}
            </EditLabelCreateNewColorSelect>
          </>
        ))}
      </div>
      <EditLabelCreateNewCreateButton
        onClick={() => {
          setShowEditLabel(!showEditLabel);
          onClickUpdateLabel();
        }}
      >
        Update
      </EditLabelCreateNewCreateButton>
    </>
  );
};

EditLabel.propTypes = {};

export default EditLabel;
