import { Listbox } from '@headlessui/react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { selectLabels } from '../../features/labels/labelsSlice';
import { ReactComponent as DownArrowLabelsDropdown } from '../../svg/down-arrow-labels-dropdown.svg';
import { Button } from '../Button/Button';

const Wrapper = styled.div`
  width: 100%;
  @media (min-width: 641px) {
    width: ${props => props.width};
  }

  .button-wrapper {
    position: relative;
  }
`;

const Options = styled(Listbox.Options)`
  background-color: white;
  position: absolute;
  width: 100%;
  overflow: auto;
  /* border: 1px solid #e3e8ee; */
  border-radius: 5px;
  box-shadow: 0px 3.35865px 8.39662px rgba(0, 0, 0, 0.08);
  padding: 0px;
  z-index: 1000;
`;

const Option = styled(Listbox.Option)`
  position: relative;
`;

const Item = styled.div`
  padding: 10px;
  display: flex;
  gap: 10px;
  cursor: pointer;

  background-color: ${props => (props.selected ? '#D7F4FC' : props.active ? '#eafafc' : '#ffffff')};
`;

const SelectButton = styled(Button)`
  border: 1px solid #e3e8ee;
  border-radius: 5px;
  padding: 10px 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;

  .dropdown-icon {
    min-width: 15px !important;
    min-height: 15px !important;
    width: 15px;
    height: 15px;
    margin: 0px;
  }
`;

const LabelColor = styled.div`
  width: 19px;
  height: 19px;
  background: ${props => props.color};
  border-radius: 5px;
`;

const defaultLabel = {
  _id: null,
  title: 'No label',
  color: '#656F6E',
};

export const HeadlessSelector = ({ setSelectedLabel, selectedLabel, width }) => {
  const labels = useSelector(selectLabels);
  const [labelState, setLabelState] = useState(defaultLabel);

  // handle selection
  const handleOnChange = value => {
    setSelectedLabel(value._id);
  };

  // Change local state based on labelId
  useEffect(() => {
    if (selectedLabel !== null) {
      const selectedLabelData = labels.find(e => e._id === selectedLabel);
      setLabelState(selectedLabelData);
    } else {
      setLabelState(defaultLabel);
    }
  }, [labels, selectedLabel]);

  return (
    <Wrapper width={width}>
      <Listbox value={labelState} onChange={handleOnChange}>
        {({ open }) => (
          <div className="button-wrapper">
            <Listbox.Button as={SelectButton} open={open}>
              <div style={{ display: 'flex', gap: '10px' }}>
                <LabelColor color={labelState.color} />
                <span>{labelState.title}</span>
              </div>
              <DownArrowLabelsDropdown className="dropdown-icon" />
            </Listbox.Button>
            <Options>
              <Option key={null} value={defaultLabel}>
                {({ selected, active }) => (
                  <Item selected={selected} active={active}>
                    <LabelColor color={defaultLabel.color} />
                    <span>No label</span>
                  </Item>
                )}
              </Option>
              {labels.map(label => (
                <Option key={label._id} value={label}>
                  {({ selected, active }) => (
                    <Item selected={selected} active={active}>
                      <LabelColor color={label.color} />
                      <span>{label.title}</span>
                    </Item>
                  )}
                </Option>
              ))}
            </Options>
          </div>
        )}
      </Listbox>
    </Wrapper>
  );
};
