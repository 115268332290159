import styled from 'styled-components';
import { Button } from '../../../Components/Button/Button';

export const Title = styled.h1`
  font-size: 25px;
  margin-top: 20px;
  margin-bottom: 25px;
  font-weight: 500;
  text-align: center;

  .underline {
    font-size: 25px;
    text-decoration: underline;
  }
`;

export const SubTitle = styled.h3`
  font-size: 18px;
  color: #5a5a5a;
  font-weight: 400;
  text-align: center;
  line-height: 25px;

  .bold {
    font-size: 20px;
    text-align: center;
    font-weight: 700;
  }
`;

export const ActionButton = styled(Button)`
  padding: 9px 35px 9px 35px;
  margin-top: 10px;
  border: 1px solid #ebebeb;
  border-radius: 5px;
  width: 100%;

  /* :hover {
    background-color: ${props => (props.variant === 'primary' ? '#18b5ca' : '#29C9DE')};
    color: ${props => (props.variant === 'primary' ? '#ffffff' : '#ffffff')};
  } */

  background-color: ${props => (props.disabled ? '#E9E9E9' : '#29c9de')};
  color: ${props => (props.disabled ? '#A5A5A5' : '#ffffff')};

  font-size: 16px;
  font-weight: 700;
  align-self: center;
`;
