import http from '../utils/http';

export async function getAllLabels(userId) {
  try {
    const result = await http.get(`/api/labels/user/${userId}`);
    return result.data;
  } catch (error) {
    console.log('error', error);
    return [];
  }
}

// create label api
export async function createLabelApi(labelObj) {
  try {
    const result = await http.post(`/api/labels`, labelObj);
    return result.data;
  } catch (error) {
    console.log('error', error);
    // return error;
  }
}
// delete label api
export async function deleteLabelApi(taskId) {
  try {
    const result = await http.delete(`/api/labels/${taskId}`);
    return result.data;
  } catch (error) {
    console.log('error', error);
    // return error;
  }
}

// update label api
export async function updateLabelApi(newLabel) {
  try {
    const result = await http.patch(`/api/labels/${newLabel._id}`, newLabel);
    return result.data;
  } catch (error) {
    console.log('error', error);
    // return error;
  }
}
