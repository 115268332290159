import React, { useState } from 'react';
import { ReactComponent as DashboardIconSVG } from '../../svg/dashboard-icon.svg';
import { ReactComponent as LabelsSVG } from '../../svg/labels.svg';
import LChevronSvg from '../../svg/left_chevron.svg';
import RChevronSvg from '../../svg/right_chevron.svg';
import { ReactComponent as MarathonIconSVG } from '../../svg/marathon-icon.svg';
import { ReactComponent as MastershopLogo } from '../../svg/mastershop_icon_logo.svg';
import { ReactComponent as ShuffleIconSVG } from '../../svg/shuffle-icon.svg';
import {
  ActionsWrapper,
  IconWrapper,
  LogoWrapper,
  MainWrapper,
  MonthButton,
  MonthSelectorWrapper,
  NavbarWrapper,
  NavButtonWrapper,
  NavigationWrapper,
  NavLabelContainer,
} from './styled/NewNavbar.styled';

import { Popover } from 'antd';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { decrement, increment, selectMonth } from '../../features/month/monthSlice';
import useWindowDimensions from '../../Hooks/useWindowDimensions';
import AddLabel from '../AddLabel/AddLabel';
import { NavButton } from '../Button/Button';
import EditLabel from '../EditLabel/EditLabel';
import LabelsDropdown from '../LabelsDropdown/LabelsDropdown';
import AddMarathonPopup from '../MarathonComponents/AddMarathon/AddMarathonPopup';
import ProfileDropdown from '../ProfileDropdown/ProfileDropdown';
import { ProfileDropdownButton } from '../ProfileDropdown/styled/ProfileDropdown.styled';
import { AddSvg } from './styled/Nav';
import { useEffect } from 'react';

export const NewNavbar = ({ type }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  console.log(type);

  const isMobile = useWindowDimensions().width <= 769;

  const { user } = useSelector(state => state.auth);
  const selectedMonth = useSelector(selectMonth);

  const [showProfileDropdown, setShowProfileDropdown] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [showAddLabel, setShowAddLabel] = useState(false);
  const [showEditLabel, setShowEditLabel] = useState(false);
  const [showLabels, setShowLabels] = useState(false);
  const [popupVisible, setPopupVisible] = useState(undefined);
  const [label, setLabel] = useState(null);
  const [isNav, setIsNav] = useState(true);

  const currentPath = path => {
    return location.pathname.split('/').slice(1).includes(path);
  };
  const handleOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const onNextMonth = event => {
    dispatch(increment());
  };
  const onPrevMonth = event => {
    dispatch(decrement());
  };

  useEffect(() => {
    if (type === undefined) {
      return setIsNav(true);
    } else {
      if (!isMobile) {
        return setIsNav(true);
      }
    }
    return setIsNav(false);
  }, [type, isMobile]);

  useEffect(() => {
    if (isMobile) {
    } else {
      setPopupVisible(undefined);
    }
  }, [isMobile, showLabels]);

  return (
    <>
      <NavbarWrapper>
        <MainWrapper>
          {isNav && (
            <>
              {!isMobile && (
                <LogoWrapper onClick={() => navigate('/')}>
                  <MastershopLogo />
                </LogoWrapper>
              )}
              <NavigationWrapper>
                <NavigationButton
                  icon={<DashboardIconSVG width={15} height={15} />}
                  title="Dashboard"
                  pageType={type}
                  type="/"
                  to="/"
                />
                <NavigationButton
                  icon={<MarathonIconSVG width={15} height={15} />}
                  title="Marathons"
                  pageType={type}
                  type="marathons"
                  to="/marathons"
                />
                <NavigationButton
                  icon={<ShuffleIconSVG width={15} height={15} />}
                  title="Shuffle"
                  pageType={type}
                  type="shuffle"
                  to="/shuffle"
                />
              </NavigationWrapper>
            </>
          )}
        </MainWrapper>
        {type !== undefined && (
          <MonthSelectorWrapper>
            <MonthButton onClick={onPrevMonth} className="btn">
              <span
                style={{
                  width: '100%',
                  height: '100%',
                  background: `url(${LChevronSvg}) no-repeat`,
                  backgroundPosition: 'center center',
                  backgroundSize: '8px',
                }}
              ></span>
            </MonthButton>
            <span className="month">{moment(selectedMonth).format('MMMM YYYY').toUpperCase()}</span>
            <MonthButton onClick={onNextMonth} className="btn">
              <span
                style={{
                  width: '100%',
                  height: '100%',
                  background: `url(${RChevronSvg}) no-repeat`,
                  backgroundPosition: 'center center',
                  backgroundSize: '8px',
                }}
              ></span>
            </MonthButton>
          </MonthSelectorWrapper>
        )}
        <ActionsWrapper>
          {type !== undefined && (
            <Popover
              style={{ borderRadius: '50px' }}
              placement="bottomRight"
              content={
                showAddLabel ? (
                  <AddLabel showAddLabel={showAddLabel} setShowAddLabel={setShowAddLabel} />
                ) : showEditLabel ? (
                  <EditLabel showEditLabel={showEditLabel} setShowEditLabel={setShowEditLabel} label={label} />
                ) : (
                  <LabelsDropdown
                    setShowAddLabel={setShowAddLabel}
                    setShowEditLabel={setShowEditLabel}
                    setLabel={setLabel}
                    setShowLabels={setShowLabels}
                    isMobile={isMobile}
                    type={type}
                  />
                )
              }
              visible={!isMobile ? undefined : showLabels}
              trigger={isMobile ? 'click' : 'hover'}
            >
              <NavButton onClick={() => setShowLabels(true)} className="btn d-flex" style={{ marginRight: '7px' }}>
                <LabelsSVG />
              </NavButton>
            </Popover>
          )}
          {type === 'marathon' && (
            <NavButton varient="primary" style={{ marginRight: '10px' }} className="btn d-flex" onClick={handleOpen}>
              <AddSvg />
            </NavButton>
          )}
          <Popover
            style={{ borderRadius: '50px' }}
            placement="bottomRight"
            content={<ProfileDropdown setShowProfileDropdown={setShowProfileDropdown} isMobile={isMobile} />}
            trigger={isMobile ? 'click' : 'hover'}
            visible={!isMobile ? undefined : showProfileDropdown}
          >
            <ProfileDropdownButton onClick={() => setShowProfileDropdown(true)}>
              {user?.firstName?.[0]?.toString()}
            </ProfileDropdownButton>
          </Popover>
        </ActionsWrapper>
      </NavbarWrapper>
      <AddMarathonPopup isOpen={isOpen} onClose={handleClose} mode="add" />
    </>
  );
};

const NavigationButton = ({ icon, title, pageType, type, to }) => {
  const isMobile = useWindowDimensions().width <= 769;
  const [isLabel, setIsLabel] = useState(false);

  useEffect(() => {
    if (pageType === undefined) {
      if (isMobile && type === '/') {
        return setIsLabel(false);
      }
    }
    return setIsLabel(true);
  }, [type, pageType, isMobile]);

  return (
    <NavButtonWrapper to={to}>
      <IconWrapper>{icon}</IconWrapper>
      {isLabel && <NavLabelContainer>{title}</NavLabelContainer>}
    </NavButtonWrapper>
  );
};
