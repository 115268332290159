import styled from 'styled-components';
import { colors } from './colors';
import CheckBox from '../Checkbox/CheckBox';

const SessionSection = styled.section`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: ${props => props.margin};
`;
export const ActionText = styled.span`
  text-decoration: none !important;
  display: flex;
  align-items: center;
  font-size: 16px;
  color: ${colors.formColor};
  user-select: none;
`;

export default function CheckBoxSection({ title, action, isActive, margin }) {
  return (
    <SessionSection margin={margin} className="session">
      <CheckBox action={action} isActive={isActive} />
      <ActionText className="action-text">{title}</ActionText>
    </SessionSection>
  );
}
