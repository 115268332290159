import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  userSignUp,
  userLogin,
  userGetProfile,
  userLogout,
  userForgotPassword,
  userResetPassword,
  userUpdatePassword,
} from './services/auth.service';

const user = JSON.parse(localStorage.getItem('user')) || JSON.parse(sessionStorage.getItem('user')) || null;

const initialState = {
  user,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
};

// Register user
export const register = createAsyncThunk(
  'auth/register',
  async ({ firstName, lastName, email, password, getPromos, keepSignedIn }, thunkAPI) => {
    try {
      const user = await userSignUp({ firstName, lastName, email, getPromos, password, keepSignedIn });

      return user;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  },
);

// Login user
export const login = createAsyncThunk('auth/login', async ({ email, password, keepSignedIn }, thunkAPI) => {
  try {
    const user = await userLogin({ email, password, keepSignedIn });
    return user;
  } catch (error) {
    console.error(error);
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const getProfile = createAsyncThunk('auth/getprofile', async (_, thunkAPI) => {
  try {
    const user = await userGetProfile();
    return user;
  } catch (error) {
    console.error(error);
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const logout = createAsyncThunk('auth/logout', async () => {
  userLogout();
});

export const forgotPassword = createAsyncThunk('auth/forgotpassword', async ({ email }, thunkAPI) => {
  try {
    const user = await userForgotPassword(email);
    return user;
  } catch (error) {
    console.error(error);
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const resetPassword = createAsyncThunk('auth/resetpassword', async ({ email, token }, thunkAPI) => {
  try {
    const user = await userResetPassword({ email, token });
    return user;
  } catch (error) {
    console.error(error);
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const updatePassword = createAsyncThunk('auth/updatepassword', async ({ email, password }, thunkAPI) => {
  try {
    const user = await userUpdatePassword({ email, password });
    return user;
  } catch (error) {
    console.error(error);
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    reset: state => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = '';
    },
  },
  extraReducers: builder => {
    builder
      .addCase(register.pending, state => {
        state.isLoading = true;
      })
      .addCase(register.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload;
      })
      .addCase(register.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.user = null;
      })
      .addCase(login.pending, state => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.error = false;
        state.user = action.payload;
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.user = null;
      })
      .addCase(logout.fulfilled, state => {
        state.user = null;
      })
      .addCase(getProfile.pending, state => {
        state.isLoading = true;
      })
      .addCase(getProfile.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.user = null;
        state.message = action.payload;
      })
      .addCase(getProfile.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.isLoading = false;
        state.user = { ...state.user, ...action.payload };
      })
      .addCase(forgotPassword.pending, state => {
        state.isLoading = true;
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.user = null;
        state.message = action.payload;
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.isLoading = false;
      })
      .addCase(resetPassword.pending, state => {
        state.isLoading = true;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isError = true;
        state.user = null;
        state.message = action.payload;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.isLoading = false;
      })
      .addCase(updatePassword.pending, state => {
        state.isLoading = true;
      })
      .addCase(updatePassword.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isError = true;
        state.user = null;
        state.message = action.payload;
      })
      .addCase(updatePassword.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.isLoading = false;
      });
  },
});

export const { reset } = authSlice.actions;
export default authSlice.reducer;
