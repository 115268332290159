import { Dialog } from '@headlessui/react';
import styled from 'styled-components';

export const TopBar = styled.div`
  display: flex;
  display: -webkit-flex;
  width: 100%;
  justify-content: end;
  -webkit-justify-content: flex-end;
  border-bottom: 1px #e3e8ee solid;
  padding: 5px;

  .svg {
    /* width: 20px;
    height: 20px; */
    color: black;

    &--delete {
      color: red;
    }
  }

  .icon {
    margin: 2px;
  }
`;

export const BottomBar = styled(TopBar)`
  border-top: 1px #e3e8ee solid;
  border-bottom: none;

  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 20px;
`;
// not used start
export const DialogContent = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  padding-bottom: 20px;
  width: 100%;

  background-color: #fcfcfc;
  border-radius: 20px;
`;
export const StyledDialog = styled(Dialog)`
  position: fixed;
  inset: 0;
  overflow-y: auto;
  z-index: 10;
`;

export const StyledOverlay = styled(Dialog.Overlay)`
  background: rgba(0 0 0 / 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: grid;
  place-items: center;
  overflow-y: auto;
  height: 100vh;
  width: 100vw;
`;

export const StyledModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
`;

export const StyledModalContent = styled.div`
  position: relative;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
`;
// not used end

export const HelperText = styled.span`
  color: #5a5a5a;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
