import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Home from './Pages/Home/Home';
import Login from './Pages/Login/Login';
import Register from './Pages/Register/Register';
import useAuth from './features/auth/hooks/useAuth';
import { getProfile } from './features/auth/authSlice';
import ForgotPassword from './Pages/ForgotPassword/ForgotPassword';
import ResetSent from './Pages/ForgotPassword/ResetSent';
import UpdatePassword from './Pages/UpdatePassword/UpdatePassword';
import Dashboard from './Pages/Dashboard/Dashboard';
import { Kalender } from './Pages/Kalender/Kalender';
import Marathon from './Pages/Marathon/Marathon';
import { getLabels } from './features/labels/labelsSlice';
import { Onboarding } from './Pages/Onboarding/Onboarding';

function AuthGuard({ children }) {
  const { isAuthenticated, getJWTToken } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = getJWTToken();
  const { user, isError } = useSelector(state => state.auth);

  const getLabelsByUser = async () => {
    dispatch(getLabels(user._id));
  };

  useEffect(() => {
    const verifyProfile = function () {
      if (!!token && isAuthenticated && user !== null && user !== undefined && !isError) {
        dispatch(getProfile({ token }));
        getLabelsByUser();
      } else {
        navigate('/register');
      }
    };
    verifyProfile();
  }, []);
  return !!token && isAuthenticated && user !== null ? children : <Navigate to="/register" />;
}

// function AnonymousGuard({ children }) {
//   const [isAuthenticated] = useAuth();

//   return !isAuthenticated ? { children } : <Navigate to="/" />;
// }

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <AuthGuard>
                <Dashboard />
              </AuthGuard>
            }
          />
          <Route
            path="/shuffle"
            element={
              <AuthGuard>
                <Home type="kalender">
                  <Kalender />
                </Home>
              </AuthGuard>
            }
          />
          <Route
            path="/marathons"
            element={
              <AuthGuard>
                <Home type="marathon">
                  <Marathon />
                </Home>
              </AuthGuard>
            }
          />
          <Route
            path="/onboarding"
            element={
              <AuthGuard>
                <Onboarding />
              </AuthGuard>
            }
          />

          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="reset-sent" element={<ResetSent />} />
          <Route path="update-password" element={<UpdatePassword />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer autoClose={3000} closeButton={true} />
    </>
  );
}

export default App;
