import http from '../../utils/http';
import apiEndpoints from '../../utils/api-endpoints';
import useAuth from '../hooks/useAuth';

export const userSignUp = ({ firstName, lastName, email, getPromos, password, keepSignedIn }) =>
  http.post(apiEndpoints.register, { firstName, lastName, email, getPromos, password }).then(response => {
    const { saveUser } = useAuth(keepSignedIn);
    const user = response.data;
    saveUser(user);
    return user;
  });

export const userLogin = ({ email, password, keepSignedIn }) =>
  http.post(apiEndpoints.login, { email, password }).then(response => {
    const { saveUser } = useAuth(keepSignedIn);
    const user = response.data;
    saveUser(user);
    return user;
  });

export const userForgotPassword = email =>
  http
    .post(apiEndpoints.forgotPassword, { email }, { headers: { 'Content-Type': 'application/json' } })
    .then(response => response.data);

export const userResetPassword = (email, token) =>
  http.get(`${apiEndpoints.resetPassword}?email=${email}&token=${token}`).then(response => response.data);

export const userUpdatePassword = ({ email, password }) =>
  http.post(apiEndpoints.updatePassword, { email, password }).then(response => response.data);

export const userGetProfile = token => http.get(apiEndpoints.getProfile).then(response => response.data);

export const userLogout = () => {
  localStorage.removeItem('user');
  sessionStorage.removeItem('user');
};

export const finishOnboarding = () => {
  return http.post(apiEndpoints.finishOnboarding).then(res => res.data);
};
