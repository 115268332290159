import styled from 'styled-components';
import { ReactComponent as LabelEditSVG } from '../../../svg/label-edit.svg';
import { ReactComponent as LabelPopoverCloseSVG } from '../../../svg/label-popover-close.svg';

export const LabelsPopOverTitle = styled.div`
  width: 48px;
  height: 20px;

  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
  margin-right: 6px;
  color: #454545;
`;
export const LabelsPopOverTitleDescription = styled.div`
  width: fit-content;
  height: 18px;

  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;

  color: #919191;
`;
export const LabelsPopOverContentBar = styled.div`
  width: 296px;
  height: 46px;
  background: ${props => props.backgroundColor};
  border-radius: 4px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  :hover {
    background: #eafafc;
  }
  cursor: pointer;
`;
export const LabelsPopOverContentTypeColor = styled.div`
  width: 32px;
  height: 32px;

  background: ${props => props.color};
  border-radius: 5px;

  margin-left: 9px;
`;
export const LabelsPopOverContentTypeText = styled.div`
  width: 127px;
  height: 18px;

  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #505050;
  margin-left: 15px;
  margin-bottom: 2px;
`;
export const LabelsPopOverContentTypeCount = styled.div`
  width: fit-content;
  height: 18px;

  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;

  color: #9d9d9d;

  margin-left: 94px;
  margin-right: 9px;
`;

export const LabelEditIcon = styled(LabelEditSVG)`
  margin-top: 16px;
  margin-left: 16px;
`;
export const LabelEditButton = styled.div`
  width: 46px;
  height: 46px;
  margin-bottom: 10px;
  margin-left: 5px;
  :hover {
    background: #eafafc;
  }
  border-radius: 4px;
  cursor: pointer;
`;
export const LabelAddButton = styled.button`
  width: 347px;
  height: 40px;
  background: #ffffff;
  border: 0.5px solid #29c9de;
  box-sizing: border-box;
  box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.1);
  border-radius: 4px;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  color: #454545;

  border: 1px solid #29c9de;
  :hover {
    background-color: ${props => (props.varient === 'primary' ? '#18b5ca' : '#29C9DE')};
    color: ${props => (props.varient === 'primary' ? '#ffffff' : '#ffffff')};
  }
`;

export const LabelPopoverClose = styled(LabelPopoverCloseSVG)`
  margin-left: 97px;
  margin-bottom: 22px;
  cursor: pointer;
`;

// start remove from here add label

// end remove from here add label
