import { Modal } from 'antd';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { createMarathon, deleteMarathon, updateMarathon } from '../../../features/marathon/marathonSlice';
import useWindowDimensions from '../../../Hooks/useWindowDimensions';
import MarathonForm from './MarathonForm';

const AddMarathonPopup = ({ isOpen, onClose, data, mode }) => {
  const dispatch = useDispatch();
  const isMobile = useWindowDimensions().width <= 769;

  const handleSubmit = useCallback(
    (formData, id) => {
      if (mode === 'add') {
        dispatch(createMarathon(formData));
        onClose();
      }
      if (mode === 'edit' && id) {
        dispatch(
          updateMarathon({
            id: id,
            payload: formData,
          }),
        );
        onClose();
      }
    },
    [dispatch, onClose, mode],
  );

  const handleDelete = () => {
    dispatch(deleteMarathon(data._id));
    onClose();
  };

  return (
    <>
      {/* Modal Popup */}
      <Modal
        visible={isOpen}
        onOk={onClose}
        onCancel={onClose}
        width={isMobile ? 'fit-content' : '40vw'}
        closable={false}
        footer={null}
        maskClosable={false}
        destroyOnClose={true}
        centered={true}
      >
        <MarathonForm
          submitHandler={handleSubmit}
          onClose={onClose}
          data={data}
          mode={mode}
          handleDelete={handleDelete}
        />
      </Modal>
    </>
  );
};

export default AddMarathonPopup;
