import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  bulkUpdateTasksApi,
  createTaskApi,
  deleteTaskApi,
  getAllTasksByMonthApi,
  getTaskByDateApi,
  markAsDoneTaskApi,
  updateTaskApi,
} from './tasksAPI';

const initialState = {
  value: [],
  status: 'idle',
  filter: {},
  totalCount: 0,
};

export const getTasksByMonth = createAsyncThunk(
  'tasks/fetchTasksByMonth',
  async ({ month, year, labelIds, naSelected }) => {
    const response = await getAllTasksByMonthApi(month, year, labelIds, naSelected);
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

// create task api
export const createTask = createAsyncThunk('tasks/createTask', async task => {
  const response = await createTaskApi(task);
  // The value we return becomes the `fulfilled` action payload
  return response;
});
// delete task api
export const deleteTask = createAsyncThunk('tasks/deleteTask', async taskId => {
  const response = await deleteTaskApi(taskId);
  // The value we return becomes the `fulfilled` action payload
  return response;
});
// update task api
export const updateTask = createAsyncThunk('tasks/updateTask', async ({ taskId, newTask }) => {
  const response = await updateTaskApi(taskId, newTask);
  // The value we return becomes the `fulfilled` action payload
  return response;
});

export const updateBulk = createAsyncThunk('tasks/updateTasksBulk', async ({ tasks }) => {
  const response = await bulkUpdateTasksApi(tasks);

  return response;
});

export const markAsDoneTask = createAsyncThunk('tasks/markAsDone', async ({ taskId }) => {
  const response = await markAsDoneTaskApi(taskId);
  return response;
});

export const getTasksByDate = createAsyncThunk('tasks/fetchDaysTasks', async ({ date }) => {
  const response = await getTaskByDateApi(date);
  return response;
});

export const tasksSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getTasksByMonth.pending, state => {
        state.status = 'loading';
      })
      .addCase(getTasksByMonth.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = action.payload.tasks;
        state.totalCount = action.payload.totalCount;
      })
      .addCase(createTask.pending, state => {
        state.status = 'loading';
      })
      .addCase(createTask.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = [...state.value, action.payload];
      })
      .addCase(deleteTask.pending, state => {
        state.status = 'loading';
      })
      .addCase(deleteTask.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = [...state.value.filter(e => e._id !== action.payload._id)];
      })
      .addCase(updateTask.pending, state => {
        state.status = 'loading';
      })
      .addCase(updateTask.fulfilled, (state, action) => {
        state.status = 'idle';
        const newState = state.value.map(task =>
          action.payload._id === task._id ? { ...task, ...action.payload } : task,
        );
        state.value = newState;
      })
      .addCase(markAsDoneTask.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(markAsDoneTask.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = [...state.value.map(v => (v._id === action.payload._id ? { ...v, ...action.payload } : v))];
      })
      .addCase(updateBulk.pending, state => {
        state.status = 'loading';
      })
      .addCase(updateBulk.fulfilled, (state, action) => {
        state.status = 'idle';
        const newState = state.value.map(task =>
          action.payload.some(p => p._id === task._id)
            ? { ...task, ...action.payload.find(t => t._id === task._id) }
            : task,
        );
        state.value = newState;
      })
      .addCase(getTasksByDate.pending, state => {
        state.status = 'pending';
        state.value = [];
      })
      .addCase(getTasksByDate.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = action.payload.tasks;
      });
    // we should add multiple cases here
  },
});

export const selectTasks = state => state.tasks.value;
export const selectTasksCount = state => state.tasks.value.length;
export const getFilter = state => state.tasks.filter;
export const getTotalCount = state => state.tasks.totalCount;
// we can have our own functions here
export const { setFilter } = tasksSlice.actions;
export default tasksSlice.reducer;
