import React from 'react';
import { EmptyContainer } from './styled/widget.styled';
import { ReactComponent as DoneSvg } from '../../svg/done_dashboard.svg';

export const EmptyPlaceholder = ({ description }) => {
  return (
    <EmptyContainer>
      <DoneSvg width="60px" />
      <span className="description">{description}</span>
    </EmptyContainer>
  );
};
