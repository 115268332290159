import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
// import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { useSelector, useDispatch } from 'react-redux';
import { reset, updatePassword } from '../../features/auth/authSlice';

import AuthWrapper from '../../Components/Auth/AuthWrapper';
import SubmitButton from '../../Components/Auth/SubmitButton';
import { InputWrapper } from '../../Components/Auth/styled';

function UpdatePassword() {
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [params, _] = useSearchParams();

  const email = params.get('email');

  const { user, isLoading, isError, isSuccess, message } = useSelector(state => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    password: '',
    password2: '',
  });
  const { password, password2 } = formData;

  useEffect(() => {
    function validateInputs() {
      let inputsValid = false;
      if (!!password?.length && !!password2.length && password === password2) {
        inputsValid = true;
      }
      return inputsValid;
    }

    setSubmitEnabled(validateInputs());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  useEffect(() => {
    if (isError) {
      toast.error(message);
    }

    if (isSuccess || user) {
      navigate('/login');
    }

    dispatch(reset());
  }, [user, isError, isSuccess, message, navigate, dispatch]);

  const onChange = e => {
    setFormData(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const onSubmit = () => {
    dispatch(updatePassword({ email, password }));
  };

  return (
    <form>
      <AuthWrapper caption="Reset Your Password">
        <InputWrapper className="gap-custom-1">
          <input
            type="password"
            name="password"
            placeholder="New Password"
            value={password}
            onChange={onChange}
            required
            maxLength="40"
          />
        </InputWrapper>
        <InputWrapper className="gap-custom-1">
          <input
            type="password"
            name="password2"
            placeholder="Confirm Password"
            value={password2}
            onChange={onChange}
            required
            maxLength="40"
          />
        </InputWrapper>
        <SubmitButton disabled={!submitEnabled} caption={`Change Password`} action={onSubmit} />
      </AuthWrapper>
    </form>
  );
}

export default UpdatePassword;
