import styled from 'styled-components';
import { ReactComponent as PlusSVG } from '../../../../svg/plus.svg';
import { ActionButton } from '../../../Button/Button';

export const Wrapper = styled.div`
  padding-top: 50px;
  /* padding-bottom: 35px; */
  border-bottom: 1px solid #cdcdcd;

  display: flex;
  flex-direction: column;
`;

export const Placeholder = styled.span`
  font-size: 16px;

  .bold {
    font-size: 16px;
    font-weight: bold;
  }
`;

export const Action = styled.div`
  margin: 20px 0 40px 0;
  display: flex;
  justify-content: center;
`;

export const AddSvg = styled(PlusSVG)`
  width: 13.16px;
  height: 25px;
`;

export const ToPortal = styled.div`
  margin-top: 5px;
  display: flex;
  justify-content: center;
`;

export const ToPortalButton = styled(ActionButton)`
  border: none;
  color: #29c9de;
  display: flex;
  background-color: transparent;

  svg {
    align-self: center;
  }

  :hover {
    color: #18b5ca;
    background-color: transparent;
  }

  .text {
    font-size: 18px;
    font-weight: 900;
    margin-left: 15px;
  }
`;
