import { Modal } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { selectLabels } from '../../features/labels/labelsSlice';
import { createTask, markAsDoneTask, updateTask } from '../../features/tasks/tasksSlice';
import { HeadlessSelector } from '../LabelsSelectDropdown/HeadlessSelector';
import { TaskEditModal } from '../TaskEditModal/TaskEditModal';
import { TaskHoverCard } from '../TaskHoverCard/TaskHoverCard';
import { AddTaskButton, ClodeAddTaskButton, StyledInput } from './styled/Input';
import './Task.css';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { isMobile } from 'react-device-detect';

const CardHeader = styled.div`
  background-color: ${props => (props.label ? props.label.color : '#656F6E')};
  padding: 5px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
`;

const CardContent = styled.div`
  font-weight: 500;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  text-align: start;
  position: relative;
`;

const TitleSpan = styled.span`
  padding: 10px;
  text-decoration: ${props => (props.isDone ? 'line-through' : 'auto')};
`;

const InputWrapper = styled.div`
  padding-top: 5px;
  padding-bottom: 5px;
  width: 100%;
  height: 125px;
  display: flex;
  flex-direction: column;
`;

const DragItem = styled.div`
  /*position: relative;*/
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  background: white;
  margin: 0 0 8px 0;
  display: flex;
  flex-direction: column;
  z-index: 1000;
  cursor: auto;
`;

const HoverCardContent = styled.div`
  position: absolute;
  border-radius: 6px;
  width: 100%;
  height: 100%;
  /* padding: 10px; */
  display: flex;
  justify-content: center;
  align-items: center;
  /*margin-top: auto;*/
  /*margin-top: -51px;*/
  /* z-index: 5500; */
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  /* cursor: pointer; */
`;

const LabelWrapper = styled.div`
  width: 100%;
  padding: 0 10px;
`;

const defaultLabel = {
  _id: null,
  title: 'No label',
  color: '#656F6E',
};

export const Task = ({ item, index, escCallback, onDeleteTask }) => {
  const dispatch = useDispatch();

  const [title, setTitle] = useState('');
  const [selectedLabel, setSelectedLabel] = useState(null);
  const [label, setLabel] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const labels = useSelector(selectLabels);

  useEffect(() => {
    setTitle(item.title);
    setSelectedLabel(item.labelId);
  }, [item]);

  useEffect(() => {
    if (selectedLabel !== null) {
      const selectedLabelData = labels.find(e => e._id === selectedLabel);
      setLabel(selectedLabelData);
    } else {
      setLabel(defaultLabel);
    }
  }, [labels, selectedLabel]);

  const onEditCallback = useCallback(() => {
    setIsOpen(true);
  }, []);

  const onDoneCallback = useCallback(() => {
    dispatch(
      markAsDoneTask({
        taskId: item._id,
      }),
    );
    setIsHover(false);
  }, [dispatch, item._id]);

  const onModalUpdateCallback = useCallback(
    element => {
      dispatch(
        updateTask({
          taskId: item._id,
          newTask: { ...element, labelId: selectedLabel },
        }),
      );
      setTitle(element.title);
      setIsOpen(false);
    },
    [dispatch, item._id, selectedLabel],
  );

  const onModalDeleteCallback = useCallback(() => {
    onDeleteTask(item._id);
    setIsOpen(false);
  }, [item._id, onDeleteTask]);

  const onModalCloseCallback = useCallback(
    element => {
      dispatch(
        updateTask({
          taskId: item._id,
          newTask: { ...element, labelId: selectedLabel },
        }),
      );
      setTitle(element.title);
      setIsOpen(false);
    },
    [dispatch, item._id, selectedLabel],
  );

  const onKeyPress = e => {
    // Enter
    if (e.keyCode === 13) {
      dispatch(
        createTask({
          title: title,
          date: item.date,
          index: item.index,
          labelId: selectedLabel,
        }),
      );
    }
    // ESC
    if (e.keyCode === 27) {
      escCallback(item._id);
    }
  };
  const onClickClose = () => {
    escCallback(item._id);
  };
  const onClickAdd = () => {
    dispatch(
      createTask({
        title: title,
        date: item.date,
        index: item.index,
        labelId: selectedLabel,
      }),
    );
  };

  const handleHover = event => {
    if (!isMobile) {
      if (event === 'Enter') {
        setIsHover(true);
      }
      if (event === 'Leave') {
        setIsHover(false);
      }
    }
  };

  const handleOutsideClick = () => {
    if (isMobile) {
      setIsHover(false);
    }
  };

  const onMobileClick = () => {
    if (isMobile) {
      setIsHover(true);
    }
  };

  const outsideRef = useDetectClickOutside({ onTriggered: handleOutsideClick });

  return (
    <>
      <Draggable draggableId={item._id} index={index} isDragDisabled={item?.isEditable}>
        {(provided, snapshot) => {
          return (
            <DragItem
              onTouchStart={() => onMobileClick()}
              onMouseEnter={e => handleHover('Enter')}
              onMouseLeave={e => handleHover('Leave')}
              // ref={provided.innerRef}
              ref={node => {
                provided.innerRef(node);
                outsideRef.current = node;
              }}
              snapshot={snapshot}
              {...provided.dragHandleProps}
              {...provided.draggableProps}
              onKeyDown={onKeyPress}
            >
              <CardContent>
                <CardHeader label={label} />
                {item?.isEditable ? (
                  <InputWrapper>
                    <StyledInput
                      type="text"
                      className="form-control"
                      placeholder="Write a Concise Title"
                      value={title}
                      onChange={e => setTitle(e.target.value)}
                      autoFocus
                    />
                    <LabelWrapper>
                      <HeadlessSelector
                        setSelectedLabel={setSelectedLabel}
                        selectedLabel={selectedLabel}
                        width="100%"
                      />
                    </LabelWrapper>
                    <div style={{ display: 'flex' }}>
                      <AddTaskButton disabled={title.length === 0} onClick={onClickAdd}>
                        Add card
                      </AddTaskButton>
                      <ClodeAddTaskButton onClick={onClickClose} />
                    </div>
                  </InputWrapper>
                ) : (
                  <>
                    <TitleSpan style={{ overflow: 'hidden', textOverflow: 'ellipsis' }} isDone={item.isDone}>
                      {title}
                    </TitleSpan>
                    {(isHover || snapshot.isDragging) && (
                      <HoverCardContent>
                        <TaskHoverCard onDoneCallback={onDoneCallback} onEditCallback={onEditCallback} />
                      </HoverCardContent>
                    )}
                  </>
                )}
              </CardContent>
            </DragItem>
          );
        }}
      </Draggable>
      <Modal
        visible={isOpen}
        onOk={() => {
          onModalUpdateCallback({ ...item, title, labelId: selectedLabel });
        }}
        onCancel={() => {
          onModalUpdateCallback({ ...item, title, labelId: selectedLabel });
        }}
        closable={false}
        footer={null}
        maskClosable={true}
        width={isMobile ? 'fit-content' : '40vw'}
      >
        <TaskEditModal
          item={item}
          onEditCallback={onModalUpdateCallback}
          onDeleteCallback={onModalDeleteCallback}
          onCloseCallback={onModalCloseCallback}
          setSelectedLabel={setSelectedLabel}
          selectedLabel={selectedLabel}
          setTitle={setTitle}
        />
      </Modal>
    </>
  );
};

export const DashboardTask = ({ item }) => {
  const labels = useSelector(selectLabels);

  const [label, setLabel] = useState(null);

  useEffect(() => {
    if (item.labelId !== null) {
      const selectedLabelData = labels.find(e => e._id === item.labelId);
      setLabel(selectedLabelData);
    } else {
      setLabel(defaultLabel);
    }
  }, [labels, item]);

  return (
    <DragItem>
      <CardContent>
        <CardHeader label={label} />
        <>
          <TitleSpan style={{ overflow: 'hidden', textOverflow: 'ellipsis' }} isDone={item.isDone}>
            {item.title}
          </TitleSpan>
        </>
      </CardContent>
    </DragItem>
  );
};
