
function useAuth(keepSignedIn = false) {
  function getIsAuthenticated() {
    const token = getJWTToken();
    let isAuthenticatedLocal = false;
    if (token) {
      isAuthenticatedLocal = JSON.parse(atob(token.split('.')[1])).exp * 1000 > Date.now();

      if (!isAuthenticatedLocal) {
        removeUser();
      }
    }
    return isAuthenticatedLocal;
  }

  function saveUser(user) {
    removeUser();
    if (keepSignedIn) {
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('keepSignedIn', true);
    } else {
      sessionStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('keepSignedIn', false);
    }
  }

  function getUser() {
    const user = JSON.parse(localStorage.getItem('user')) || JSON.parse(sessionStorage.getItem('user')) || null;
    return user;
  }

  function getJWTToken() {
    return getUser()?.token || null;
  }

  function removeUser() {
    localStorage.removeItem('user');
    sessionStorage.removeItem('user');
  }
  return { isAuthenticated: getIsAuthenticated(), saveUser, getUser, getJWTToken, removeUser };
}

export default useAuth;
