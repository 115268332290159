import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  keepSignedIn: false,
};

const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    toggleSession: state => {
      state.keepSignedIn = !state.keepSignedIn;
    },
  },
});

export const { toggleSession } = sessionSlice.actions;

export const selectSession = state => state.session.keepSignedIn;

export default sessionSlice.reducer;
