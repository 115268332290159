import { StyledSubmitButton } from '../../Components/Auth/SubmitButton';
import AuthWrapper from '../../Components/Auth/AuthWrapper';
import { useNavigate } from 'react-router-dom';

function ResetSent() {
  const navigate = useNavigate();
  return (
    <AuthWrapper>
      <StyledSubmitButton onClick={e => navigate('/login')}>Your Password Reset Instructions Have Been Sent</StyledSubmitButton>
    </AuthWrapper>
  );
}

export default ResetSent;
