import styled from 'styled-components';

export const Dates = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow: hidden;
`;

export const DayBtn = styled.div`
  margin-left: 2px;
  margin-right: 2px;

  /* Mobile */
  @media (min-width: 320px) {
    min-width: 11.8vw;
    min-height: 11.8vw;
  }

  /* Tablet */
  @media (min-width: 641px) {
    min-width: 5.38vw;
    min-height: 5.38vw;
  }

  /* Latop, Desktop */
  @media (min-width: 961px) {
    min-width: 2.38vw;
    min-height: 2.38vw;
  }

  border-radius: 50%;
  background-color: ${props => (props.isToday ? '#626C6D' : 'transparent')};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => (props.thisMonth ? (props.isToday ? '#ffffff' : '#558C93') : '#6AAFB8')};
`;
