import styled from 'styled-components';

export const Bar = styled.header`
  width: 100%;
  height: 54px;
  background-color: #fcfcfc;
  display: flex;
  justify-content: space-between;
  align-items: center;
  right: 0;
  left: 0;

  .logo {
    flex-direction: column;
    margin-left: 20px;
  }
`;

export const Logo = styled.div`
  @import url('https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap');

  margin-left: 20px;
  align-items: center;
  flex-direction: column;
  align-items: start;

  svg {
    margin-left: 10px;
    margin-right: 10px;
  }

  .title {
    font-size: 1.6rem;
    font-family: 'Noto Sans', sans-serif;
  }

  @media (min-width: 320px) {
    display: flex;
  }

  @media (min-width: 961px) {
    display: flex;
  }
`;

export const DashboardContent = styled.div`
  position: absolute;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  top: 54px;
  right: 0;
  left: 0;
  width: 100%;

  @media (min-width: 320px) {
    flex-direction: column;
  }

  @media (min-width: 961px) {
    flex-direction: row;
  }
`;
