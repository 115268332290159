// import axios from "axios";
import http from '../utils/http';
// import moment from "moment";
import moment from 'moment-timezone';
// axios.defaults.baseURL = "http://localhost:3005";

/*
our data structure 
{ 
   _id: mongooseId , 
   title:task Title - String ,
   date: date of task - Date , 
   week- week of task - string ,
   month:month of task -String 
   labelId: labelId-string || null
} 
*/
export async function getAllTasksByMonthApi(month = 1, year = 2022, labelIds = [], naSelected) {
  try {
    const tz = moment.tz.guess();
    let url = `/api/tasks/year/${year}/month/${month}?timezone=${tz}`;
    if (naSelected === true) {
      url = `${url}&naSelected=${true}`;
    }
    if (labelIds.length > 0) {
      labelIds.forEach(labelId => {
        url += `&labelIds=${labelId}`;
      });
    }
    const result = await http.get(url);

    // const data = refactorTasksData(result.data);

    return result.data;
  } catch (error) {
    console.log('error', error);
    return [];
  }
}

// create task api
export async function createTaskApi(taskObj) {
  try {
    const result = await http.post(`/api/tasks`, taskObj);

    return result.data;
  } catch (error) {
    console.log('error', error);
    // return error;
  }
}
// delete task api
export async function deleteTaskApi(taskId) {
  try {
    const result = await http.delete(`/api/tasks/${taskId}`);

    // const data = refactorDeleteTasksData(result.data);

    return result.data;
  } catch (error) {
    console.log('error', error);
    // return error;
  }
}

// update task api
export async function updateTaskApi(taskId, newTask) {
  try {
    const result = await http.patch(`/api/tasks/${taskId}`, newTask);

    // const data = refactorUpdateTasksData(result.data);

    return result.data;
  } catch (error) {
    console.log('error', error);
    // return error;
  }
}

export async function markAsDoneTaskApi(taskId, newTask) {
  try {
    const result = await http.put(`/api/tasks/${taskId}`, newTask);

    // const data = refactorUpdateTasksData(result.data);

    return result.data;
  } catch (error) {
    console.log('error', error);
    // return error;
  }
}
// update task api
export async function getTaskByMonthWeekIndex(taskIndex, taskMonth, taskWeek) {
  try {
    const result = await http.get(`/api/tasks/month-week-index/${taskMonth}/${taskWeek}/${taskIndex}`);

    return result.data;
  } catch (error) {
    console.log('error', error);
    // return error;
  }
}

export async function bulkUpdateTasksApi(tasks) {
  try {
    const result = await http.post('/api/tasks/bulk', {
      newTasks: tasks,
    });

    return result.data;
  } catch (error) {
    console.log('error', error);
  }
}

export async function getTaskByDateApi(date) {
  try {
    const result = await http.get(`/api/tasks/dashboard/date?` + new URLSearchParams({ date }));

    return result.data;
  } catch (error) {
    console.log('error', error);
    // return error;
  }
}
