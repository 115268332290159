import { DatePicker } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { selectMonth } from '../../../features/month/monthSlice';
import { ActionButton as ActionButtonOnboarding } from '../../../Pages/Onboarding/pages/Onboarding.styled';
import { ReactComponent as ClockSVG } from '../../../svg/clock.svg';
import { ReactComponent as CloseSVG } from '../../../svg/close.svg';
import { ReactComponent as DeleteSVG } from '../../../svg/delete.svg';
import { ReactComponent as EditTaskLabel } from '../../../svg/edit-task-label.svg';
import { ReactComponent as LeadingIconSVG } from '../../../svg/leading-icon1.svg';
import { ActionButton, Button } from '../../Button/Button';
import Checkbox from '../../Checkbox/CheckBox';
import Input from '../../Input/Input';
import { HeadlessSelector } from '../../LabelsSelectDropdown/HeadlessSelector';
import { BottomBar, DialogContent, HelperText, TopBar } from '../../StyledModal/StyledModal';
import {
  AllDayWrapper,
  ContentWrapper,
  DateInputWrapper,
  ProgressInputWrapper,
  ProgressLine,
  ProgressLineWrapper,
  TaskSelectWrapper,
  UpdateBanner,
  UpdateContent,
  UpdateHeader,
  UpdateInput,
} from './styled/MarathonForm.styled';

function MarathonForm({ submitHandler, onClose, data, mode, handleDelete, isOnboarding = false }) {
  const selectedMonth = useSelector(selectMonth);

  const [input, setInput] = useState({
    title: '',
    goalProgress: '0',
    goalTotal: '',
    goalTask: '',
    goalVerb: '',
  });
  const [inputErrors, setInputErrors] = useState({
    title: '',
    goalProgress: '',
    goalTotal: '',
    goalTask: '',
    goalVerb: '',
    dateRangeFrom: '',
    dateRangeTo: '',
    isAllMonth: '',
  });
  const [dateRangeFrom, setDateRangeFrom] = useState(new Date());
  const [dateRangeTo, setDateRangeTo] = useState(moment(new Date()).endOf('month').toDate());
  const [isAllMonth, setIsAllMonth] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState(null);
  const [updateBg, setUpdateBg] = useState('#29C9DE');

  const schema = yup.object().shape({
    title: yup.string().required(),
    goalProgress: yup.number().required(),
    goalTotal: yup.number().required(),
    goalTask: yup.string().required(),
    goalVerb: yup.string().required(),
    dateRangeFrom: yup.date().required(),
    dateRangeTo: yup.date().required(),
    isAllMonth: yup.boolean(),
  });

  const handleAllMonth = () => {
    setIsAllMonth(!isAllMonth);
  };

  const handleInput = (e, field) => {
    setInput({
      ...input,
      [field]: e.target.value,
    });
    setInputErrors({
      ...inputErrors,
      [field]: '',
    });
  };

  const handleSubmit = async () => {
    // reset form errors
    setInputErrors({
      title: '',
      goalProgress: '',
      goalTotal: '',
      goalTask: '',
      goalVerb: '',
      dateRangeFrom: '',
      dateRangeTo: '',
      isAllMonth: '',
    });
    schema
      .validate(
        {
          ...input,
          dateRangeFrom,
          dateRangeTo,
          isAllMonth,
        },
        {
          abortEarly: false,
        },
      )
      .then(validInput => {
        submitHandler(
          {
            ...validInput,
            labelId: selectedLabel,
          },
          data?._id,
        );
      })
      .catch(err => {
        // set form errors
        if (err) {
          err.inner.forEach(error => {
            setInputErrors(prev => {
              return {
                ...prev,
                [error.path]: error.message,
              };
            });
          });
        }
      });
  };

  useEffect(() => {
    if (mode === 'edit' && data) {
      setInput({
        title: data.title,
        goalProgress: data.goalProgress,
        goalTotal: data.goalTotal,
        goalTask: data.goalTask,
        goalVerb: data.goalVerb,
      });

      setDateRangeFrom(new Date(data.dateRangeFrom));
      setDateRangeTo(new Date(data.dateRangeTo));
      setIsAllMonth(data.isAllMonth);
      setSelectedLabel(data.labelId);
    }
  }, [data]);

  useEffect(() => {
    if (isAllMonth) {
      const m = moment(selectedMonth);
      setDateRangeFrom(m.startOf('month').toDate());
      setDateRangeTo(m.endOf('month').toDate());
    }
  }, [isAllMonth, selectedMonth]);

  useEffect(() => {
    if (mode === 'add') {
      const m = moment(selectedMonth);
      if (m.isSame(new Date(), 'month')) {
        setDateRangeFrom(new Date());
      } else {
        setDateRangeFrom(m.startOf('month').toDate());
      }
      setDateRangeTo(m.endOf('month').toDate());
    }
  }, [selectedMonth, mode]);

  const handleProgress = e => {
    const value = e.target.value;
    setInput({
      ...input,
      goalProgress: value,
    });
    setInputErrors({
      ...inputErrors,
      goalProgress: '',
    });
    if (mode === 'edit' && value !== data.goalProgress) {
      // dispatch(
      //   updateMarathonProgress({
      //     id: data._id,
      //     payload: {
      //       _id: data._id,
      //       goalProgress: value,
      //     },
      //   }),
      // );
      setUpdateBg('#3AD741');
    }
  };

  const onDateChange = (date, dateString, mode) => {
    if (date !== null) {
      if (mode === 'from') {
        setDateRangeFrom(date);
      }
      if (mode === 'to') {
        setDateRangeTo(date);
      }
    }
  };

  const onboardingAction = () => {
    handleSubmit();
  };

  const onOutsideSubmit = e => {
    if (!isOnboarding) {
      if (e?.target?.className === 'ant-modal-wrap ant-modal-centered') {
        handleSubmit();
      }
    }
  };

  const dateFormat = value => `${value.format('dddd, DD YYYY')}`;

  const outsideRef = useDetectClickOutside({ onTriggered: onOutsideSubmit });

  return (
    <div ref={outsideRef} id="Modal_trigger">
      {!isOnboarding && (
        <TopBar>
          {mode === 'edit' && (
            <Button className="btn btn-outline icon" onClick={handleDelete}>
              <DeleteSVG className="svg" />
            </Button>
          )}
          <Button className="btn btn-outline icon" onClick={onClose}>
            <CloseSVG className="svg" />
          </Button>
        </TopBar>
      )}
      {mode === 'edit' && data && (
        <UpdateBanner bgColor={updateBg}>
          <div className="box-shadow">
            <UpdateContent>
              <UpdateHeader>Update progress</UpdateHeader>
              <UpdateInput>
                <Input
                  name="edit-progress"
                  classNameWrapper="number-input"
                  varient="small"
                  placeholder="0"
                  value={input['goalProgress']}
                  onChange={handleProgress}
                  type="number"
                />
                <span className="text">
                  {input.goalTask} {input.goalVerb}
                </span>
              </UpdateInput>
            </UpdateContent>
          </div>
        </UpdateBanner>
      )}
      <DialogContent>
        <ContentWrapper>
          <Input
            name="title"
            classNameWrapper="title-box"
            varient="big"
            hint="eg. “Write 10 blog posts”"
            placeholder="Write a quantifiable goal title"
            value={input['title']}
            onChange={e => handleInput(e, 'title')}
            error={inputErrors['title'].length > 0}
          />
          {/* TODO: Label Selector */}
          <TaskSelectWrapper>
            <EditTaskLabel style={{ width: '20px', height: '20px', marginRight: '15px' }} />
            {/* <LabelsSelectDropdown
              width="60%"
              setSelectedLabel={setSelectedLabel}
              selectedLabel={selectedLabel}
              setLabel={setLabel}
            /> */}
            <HeadlessSelector setSelectedLabel={setSelectedLabel} selectedLabel={selectedLabel} width="320px" />
          </TaskSelectWrapper>
          <ProgressInputWrapper>
            <LeadingIconSVG />
            <ProgressLineWrapper onboarding={isOnboarding}>
              <ProgressLine width="90%">
                <Input
                  name="goalProgress"
                  varient="small"
                  classNameWrapper="input-box input-box--sm input-box--l"
                  hint="eg. 0"
                  placeholder="0"
                  value={input['goalProgress']}
                  onChange={handleProgress}
                  error={inputErrors['goalProgress'].length > 0}
                  type="number"
                />
                <span className="text">Out of</span>
                <Input
                  name="goalTotal"
                  varient="small"
                  classNameWrapper="input-box input-box--sm"
                  hint="eg. 10"
                  placeholder="Total"
                  value={input['goalTotal']}
                  onChange={e => handleInput(e, 'goalTotal')}
                  error={inputErrors['goalTotal'].length > 0}
                  type="number"
                />
              </ProgressLine>
              <ProgressLine width="100%" margin>
                <Input
                  name="goalTask"
                  varient="small"
                  classNameWrapper="input-box input-box--lg"
                  hint="eg. blog posts"
                  placeholder="Task"
                  value={input['goalTask']}
                  onChange={e => handleInput(e, 'goalTask')}
                  error={inputErrors['goalTask'].length > 0}
                />
                <Input
                  name="goalVerb"
                  varient="small"
                  classNameWrapper="input-box input-box--r input-box--lg"
                  hint="eg. written"
                  placeholder="Verb"
                  value={input['goalVerb']}
                  onChange={e => handleInput(e, 'goalVerb')}
                  error={inputErrors['goalVerb'].length > 0}
                />
              </ProgressLine>
            </ProgressLineWrapper>
          </ProgressInputWrapper>
          {/* <DateInput
            date={dateRangeFrom}
            setDate={setDateRangeFrom}
            disabled={isAllMonth}
            defaultMonth={new Date(selectedMonth)}
            left="-5vw"
          /> */}
          <DateInputWrapper>
            <ClockSVG />
            <DatePicker
              onChange={(date, dateString) => onDateChange(date, dateString, 'from')}
              disabled={isAllMonth}
              value={moment(dateRangeFrom)}
              bordered={false}
              format={dateFormat}
              suffixIcon={<></>}
              className="picker"
              allowClear={false}
            />
            <span className="text">-</span>
            <DatePicker
              onChange={(date, dateString) => onDateChange(date, dateString, 'to')}
              disabled={isAllMonth}
              value={moment(dateRangeTo)}
              bordered={false}
              format={dateFormat}
              suffixIcon={<></>}
              className="picker"
              allowClear={false}
            />
            {/* <DateInput
              date={dateRangeTo}
              setDate={setDateRangeTo}
              disabled={isAllMonth}
              defaultMonth={new Date(selectedMonth)}
              left="-45vw"
            /> */}
          </DateInputWrapper>
          <AllDayWrapper>
            <Checkbox varient="small" action={handleAllMonth} isActive={isAllMonth} />
            <span className="text">All Month</span>
          </AllDayWrapper>
        </ContentWrapper>
      </DialogContent>
      {!isOnboarding ? (
        <BottomBar>
          <ActionButton className="btn d-flex" onClick={onClose}>
            <span className="btn-text">Cancel</span>
          </ActionButton>
          <ActionButton varient="primary" className="btn d-flex" onClick={() => handleSubmit()}>
            <span className="btn-text">Save</span>
          </ActionButton>
        </BottomBar>
      ) : (
        <>
          <HelperText>You can add as many goals as you want after setup.</HelperText>
          <ActionButtonOnboarding onClick={onboardingAction}>NEXT</ActionButtonOnboarding>
        </>
      )}
    </div>
  );
}

export default MarathonForm;
