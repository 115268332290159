import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL || 'http://localhost:3005';

function getJWTToken() {
  const token =
    (JSON.parse(localStorage.getItem('user')) || JSON.parse(sessionStorage.getItem('user')) || null)?.token || null;
  return token;
}

function authRequestInterceptor(config) {
  const token = getJWTToken();
  if (token) {
    config.headers.authorization = `Bearer ${token}`;
  }
  config.headers.Accept = 'application/json';
  return config;
}

const instance = axios.create({
  baseURL: BASE_URL,
});

instance.interceptors.request.use(authRequestInterceptor);

export default instance;
