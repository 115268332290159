import { configureStore } from '@reduxjs/toolkit';
import tasksReducer from '../features/tasks/tasksSlice';
import monthReducer from '../features/month/monthSlice';
import sessionReducer from '../features/auth/session/sessionSlice';
import authReducer from '../features/auth/authSlice';
import marathonReducer from '../features/marathon/marathonSlice';
import labelsReducer from '../features/labels/labelsSlice';

export const store = configureStore({
  reducer: {
    tasks: tasksReducer,
    month: monthReducer,
    session: sessionReducer,
    auth: authReducer,
    marathon: marathonReducer,
    labels: labelsReducer,
  },
});
