import React from 'react';
import { Container, SecondryTitle, Title, TitleContainer } from './styled/widget.styled';

export const Widget = ({ title, subtitle, children }) => {
  return (
    <Container>
      <TitleContainer>
        <Title>{title}</Title>
        <SecondryTitle>{subtitle}</SecondryTitle>
      </TitleContainer>
      {children}
    </Container>
  );
};
