import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { OnboardingLabel } from '../../../Components/AddLabel/OnboardingLabel';
import useAuth from '../../../features/auth/hooks/useAuth';
import { createLabelApi, updateLabelApi } from '../../../features/labels/labelsAPI';
import { wordify } from '../../../features/utils/numberToText';
import { ActionButton, SubTitle, Title } from './Onboarding.styled';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;
const LabelWrapper = styled.div`
  width: 100%;
`;

export const StepLabel = ({ onNext }) => {
  const auth = useAuth();

  const [labelData, setLabelData] = useState([]);
  const [doneCount, setDoneCount] = useState(3);

  useEffect(() => {
    const defaultData = Array(3)
      .fill()
      .map((_, i) => {
        return { index: i, status: 'init', type: 'init' };
      });
    setLabelData(defaultData);
    return () => {
      setLabelData([]);
    };
  }, []);

  useEffect(() => {
    const doneLabelLen = labelData.filter(l => l.status === 'init' && l.type === 'init').length;
    setDoneCount(doneLabelLen);
    const isAllDone = labelData.filter(l => l.status === 'done').length;
    if (isAllDone === labelData.length && isAllDone >= 3) {
      setLabelData([...labelData, { index: labelData.length, status: 'init', type: 'optional' }]);
    }
  }, [labelData]);

  const handleLabelSubmit = (data, index, status) => {
    if (status === 'init') {
      const label = {
        userId: auth.getUser()._id,
        title: data.label,
        color: data.color.color,
        shade: data.color.shade,
      };
      createLabelApi(label)
        .then(res => {
          setLabelData(labelData.map(label => (label.index === index ? { ...label, status: 'done', ...res } : label)));
        })
        .catch(err => console.log(err));
    }
    if (status === 'edit') {
      const label = {
        ...labelData.find(l => l.index === index),
        userId: auth.getUser()._id,
        title: data.label,
        color: data.color.color,
        shade: data.color.shade,
      };
      updateLabelApi(label)
        .then(res => {
          setLabelData(labelData.map(label => (label.index === index ? { ...label, status: 'done', ...res } : label)));
        })
        .catch(err => console.log(err));
    }
  };

  const handleLabelEditTrigger = index => {
    setLabelData(labelData.map(label => (label.index === index ? { ...label, status: 'edit' } : label)));
  };

  const handleNext = () => {
    onNext({ status: 'done', count: labelData.filter(l => l.status === 'done').length }, 'label');
  };

  console.log(labelData);

  return (
    <Container>
      <Title>Create labels to categorize your tasks and goals</Title>
      <SubTitle>
        A label can be a <span className="bold">project</span> (eg. Website Launch), a{' '}
        <span className="bold">north star</span> (eg. Improve Fitness), or a <span className="bold">skill</span> (eg.
        Writing)
      </SubTitle>
      <LabelWrapper>
        {labelData.map(data => (
          <OnboardingLabel
            key={data.index}
            index={data.index}
            data={data}
            onSubmit={handleLabelSubmit}
            editTrigger={handleLabelEditTrigger}
          />
        ))}
      </LabelWrapper>
      <ActionButton onClick={handleNext} disabled={doneCount > 0} style={{ textTransform: 'uppercase' }}>
        {doneCount > 0 ? <>{`add ${wordify(doneCount)} more`}</> : <>{'Next'}</>}
      </ActionButton>
    </Container>
  );
};
