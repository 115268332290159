import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as LabelColorSelectTickSVG } from '../../svg/label-color-select-tick.svg';
import { ReactComponent as LabelEditSVG } from '../../svg/label-edit.svg';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Input = styled.input`
  width: 100%;
  font-size: 15px;
  padding: 10px;
  text-indent: 10px;
  background: #ffffff;
  border: 1px solid #e3e8ee;
  box-sizing: border-box;
  border-radius: 5px;
  margin-top: 15px;
`;

const LabelColorSelectWrapper = styled.div`
  display: grid;
  width: 100%;
  gap: 10px 10px;
  grid-template-columns: auto auto auto;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const LabelColorSelect = styled.div`
  background: ${props => props.color};
  border-radius: 10px;
  cursor: pointer;
  height: 60px;
  width: 100%;
  position: relative;
`;

export const LabelColorSelectTick = styled(LabelColorSelectTickSVG)`
  z-index: 5;
  color: #ffffff;
  position: absolute;
  width: 50px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
`;

const FieldError = styled.span`
  display: flex;
  justify-content: start;
  align-self: flex-start;
  margin-top: 5px;
`;

const types = [
  { color: '#89E18D', shade: '#DCF9DD' },
  { color: '#E2A161', shade: '#F7D6B7' },
  { color: '#4674B1', shade: '#BDD5F4' },
  { color: '#7BC6B1', shade: '#F4E0E0' },
  { color: '#EFB5B4', shade: '#F4E0E0' },
  { color: '#ECE690', shade: '#E3E3E3' },
];

export const OnboardingLabel = ({ index, onSubmit, data, editTrigger }) => {
  const [isEdit, setIsEdit] = useState(false);
  const [value, setValue] = useState(data?.label || '');
  const [error, setError] = useState('');
  const [selectedColor, setSelectedColor] = useState(data?.color || {});

  useEffect(() => {
    if (value.length > 0) {
      setIsEdit(true);
    } else {
      setIsEdit(false);
    }

    return () => {
      setIsEdit(false);
    };
  }, [value]);

  const handleInput = event => {
    event.preventDefault();
    setValue(event.target.value);
  };

  const onClickLabelColor = (e, color) => {
    if (value.length > 0) {
      if (selectedColor.color !== color) {
        setSelectedColor(color);
      } else {
        setSelectedColor({});
      }
      onSubmit({ label: value, color: color }, index, data.status);
    } else {
      setError('Label cannot be blank');
    }
  };

  return (
    <Container>
      {data.status === 'init' || data.status === 'edit' ? (
        <>
          <Input
            value={value}
            onChange={handleInput}
            placeholder={`Write a label here ${data.type === 'optional' ? '(Optional)' : ''}`}
            onClick={() => setIsEdit(true)}
          />
          {error.length > 0 && <FieldError>{error}</FieldError>}
          {isEdit && (
            <LabelColorSelectWrapper>
              {types.map((type, idx) => (
                <LabelColorSelect key={idx} color={type.color} onClick={e => onClickLabelColor(e, type)}>
                  {selectedColor.color === type.color && <LabelColorSelectTick />}
                </LabelColorSelect>
              ))}
            </LabelColorSelectWrapper>
          )}
        </>
      ) : (
        <div style={{ display: 'flex', alignItems: 'center', width: '100%', marginBottom: '10px' }}>
          <LabelsEditViewContentBar backgroundColor="#f4f8f8">
            <LabelsEditViewTypeColor color={data.color} />
            <LabelsPopOverContentTypeText>{data.title}</LabelsPopOverContentTypeText>
          </LabelsEditViewContentBar>
          <LabelEditButton onClick={() => editTrigger(index)}>
            <LabelEditIcon />
          </LabelEditButton>
        </div>
      )}
    </Container>
  );
};

const LabelsEditViewContentBar = styled.div`
  width: 100%;
  padding: 10px;
  background: ${props => props.backgroundColor};
  border-radius: 4px;
  display: flex;
  align-items: center;
  :hover {
    background: #eafafc;
  }
  cursor: pointer;
`;

const LabelsEditViewTypeColor = styled.div`
  width: 32px;
  height: 32px;
  background: ${props => props.color};
  border-radius: 5px;
`;

const LabelsPopOverContentTypeText = styled.div`
  width: 127px;
  height: 18px;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #505050;
  margin-left: 15px;
  margin-bottom: 2px;
`;

const LabelEditIcon = styled(LabelEditSVG)`
  margin-top: 16px;
  margin-left: 16px;
`;

const LabelEditButton = styled.div`
  width: 46px;
  height: 46px;
  margin-left: 5px;
  :hover {
    background: #eafafc;
  }
  border-radius: 4px;
  cursor: pointer;
`;
