import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import MarathonForm from '../../../Components/MarathonComponents/AddMarathon/MarathonForm';
import { createMarathon } from '../../../features/marathon/marathonSlice';
import { SubTitle, Title } from './Onboarding.styled';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const GoalWrapper = styled.div`
  width: 100%;
`;

export const StepGoal = ({ onNext }) => {
  const dispatch = useDispatch();

  const handleNext = useCallback(() => {
    onNext({ status: 'done', count: 1 }, 'goal');
  }, [onNext]);

  const handleSubmit = useCallback(
    (formData, id) => {
      dispatch(createMarathon(formData));
      handleNext();
    },
    [dispatch, handleNext],
  );

  return (
    <Container>
      <Title>Write one goal</Title>
      <SubTitle>
        A goal consists of a title, a label, your current progress in quantifiable terms, and a date range
      </SubTitle>
      <GoalWrapper>
        <MarathonForm submitHandler={handleSubmit} isOnboarding={true} mode={'add'} />
      </GoalWrapper>
    </Container>
  );
};
