import moment from 'moment';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { WeekColumn } from '../../Components/WeekColumn/WeekColumn';
import useAuth from '../../features/auth/hooks/useAuth';
import { selectMonth } from '../../features/month/monthSlice';
import { getFilter, getTasksByMonth, selectTasks, updateBulk } from '../../features/tasks/tasksSlice';
import { refactorTasksData } from '../../Utils/refactorTasksData';
import { move, reorder } from '../../Utils/reorder';

const DragDropContextContainer = styled.div`
  position: fixed;
  margin-top: 54px;
  width: 100%;
  overflow: auto;
  height: calc(100vh - 54px);
`;

const ListGrid = styled.div`
  display: grid;
`;

export function Kalender() {
  const dispatch = useDispatch();

  const tasks = useSelector(selectTasks);
  const selectedMonth = useSelector(selectMonth);
  const filter = useSelector(getFilter);

  const [dates, setDates] = useState({});
  const [elements, setElements] = useState({});
  const weeks = Object.keys(elements);

  const getTasks = () => {
    const m = moment(selectedMonth);
    const filterCriteria = {
      ...filter,
      month: m.month() + 1,
      year: m.year(),
    };
    dispatch(getTasksByMonth(filterCriteria));
  };

  // Transform to view data format when Tasks are updated
  useEffect(() => {
    if (tasks) {
      const m = moment(selectedMonth);
      const data = refactorTasksData(tasks, m.month() + 1, m.year());

      setElements({ ...data });
    }
  }, [tasks]);

  // Fetch Tasks when month is changed
  useEffect(() => {
    getTasks();
  }, [selectedMonth]);

  useEffect(() => {
    const m = moment(selectedMonth);
    const startDate = m.clone().startOf('month').startOf('week');
    const endDate = m.clone().endOf('month');

    const day = startDate.clone().subtract(1, 'day');

    let days = {};
    let weekIdx = 0;
    while (day.isBefore(endDate, 'day')) {
      days = {
        ...days,
        [weekIdx]: Array(7)
          .fill({})
          .map(() => {
            day.add(1, 'day');
            return {
              day: day.clone().format('DD'),
              year: day.clone().year(),
              month: day.clone().month() + 1,
            };
          }),
      };
      weekIdx++;
    }

    setDates({ ...days });

    return () => {
      setDates({});
    };
    // const displayCount = Math.round(width / 60);
  }, [selectedMonth]);

  // console.log("tasks", tasks);
  // console.log("month", selectedMonth);
  // console.log("ele", elements);

  const onDragEnd = result => {
    const m = moment(selectedMonth);
    const month = m.month() + 1;
    const year = m.year();

    if (!result.destination) {
      return;
    }

    let listCopy = { ...elements };

    // DroppableID contains `${week}-${date}`
    const [destWeek, destDate] = result.destination.droppableId.split('-');
    const [srcWeek, srcDate] = result.source.droppableId.split('-');

    if (destWeek === srcWeek) {
      // Moves of same week
      if (destDate === srcDate) {
        // Moves of same date (Reorder)
        // Change Task.Index -> Destination.Index

        const resultList = reorder(
          listCopy[srcWeek].data[srcDate].data,
          result.source.index,
          result.destination.index,
        ).map((ele, idx) => {
          return {
            ...ele,
            index: idx,
          };
        });

        listCopy = {
          ...listCopy,
          [srcWeek]: {
            ...listCopy[srcWeek],
            data: {
              ...listCopy[srcWeek].data,
              [srcDate]: {
                ...listCopy[srcWeek].data[srcDate],
                data: resultList,
              },
            },
          },
        };

        dispatch(updateBulk({ tasks: resultList }));
        setElements({ ...listCopy });
      } else {
        // Moves between days of same week
        // Change Task.Date -> Destination.Date, Task.Index -> Destination.Index

        const destinationDate = moment(`${destDate}-${month}-${year} 01:00`, 'DD-MM-YYYY hh:mm');

        const resultList = move(
          listCopy[srcWeek].data[srcDate].data,
          listCopy[destWeek].data[destDate].data,
          result.source.index,
          srcDate,
          result.destination.index,
          destDate,
          destinationDate.toISOString(),
        );

        listCopy = {
          ...listCopy,
          [srcWeek]: {
            ...listCopy[srcWeek],
            data: {
              ...listCopy[srcWeek].data,
              [srcDate]: {
                ...listCopy[srcWeek].data[srcDate],
                data: resultList[srcDate],
              },
              [destDate]: {
                ...listCopy[destWeek].data[destDate],
                data: resultList[destDate],
              },
            },
          },
        };

        let newTasks = [];
        Object.keys(resultList).forEach(k => (newTasks = [...newTasks, ...resultList[k]]));

        dispatch(updateBulk({ tasks: newTasks }));
        setElements({ ...listCopy });
      }
    } else {
      // Moves between two week columns
      const destinationDate = moment(`${destDate}-${month}-${year} 01:00`, 'DD-MM-YYYY hh:mm');

      const resultList = move(
        listCopy[srcWeek].data[srcDate].data,
        listCopy[destWeek].data[destDate].data,
        result.source.index,
        srcDate,
        result.destination.index,
        destDate,
        destinationDate.toISOString(),
      );

      listCopy = {
        ...listCopy,
        [srcWeek]: {
          ...listCopy[srcWeek],
          data: {
            ...listCopy[srcWeek].data,
            [srcDate]: {
              ...listCopy[srcWeek].data[srcDate],
              data: resultList[srcDate],
            },
          },
        },
        [destWeek]: {
          ...listCopy[destWeek],
          data: {
            ...listCopy[destWeek].data,
            [destDate]: {
              ...listCopy[destWeek].data[destDate],
              data: resultList[destDate],
            },
          },
        },
      };

      let newTasks = [];
      Object.keys(resultList).forEach(k => (newTasks = [...newTasks, ...resultList[k]]));

      dispatch(updateBulk({ tasks: newTasks }));
      setElements({ ...listCopy });
    }
  };

  return (
    <DragDropContextContainer>
      <DragDropContext onDragEnd={onDragEnd}>
        <ListGrid size={weeks.length}>
          {weeks.map(
            (week, wIdx) =>
              elements.hasOwnProperty(week) && (
                <WeekColumn
                  elements={elements[week]?.data}
                  total={elements[week]?.total}
                  key={week}
                  prefix={week}
                  length={Object.keys(elements).length}
                  title={elements[week]?.title}
                  dates={dates[wIdx]}
                  thisMonth={moment(selectedMonth).month() + 1}
                />
              ),
          )}
        </ListGrid>
      </DragDropContext>
    </DragDropContextContainer>
  );
}
