import React, { useEffect, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import EmptyMarathon from '../../Components/MarathonComponents/EmptyMarathon/EmptyMarathon';
import {
  ToPortal,
  ToPortalButton,
} from '../../Components/MarathonComponents/EmptyMarathon/styled/EmptyMarathon.styled';
import ViewMarathons from '../../Components/MarathonComponents/ViewMarathons/ViewMarathons';
import useAuth from '../../features/auth/hooks/useAuth';
import { getMarathonsByMonth, selectMarathons } from '../../features/marathon/marathonSlice';
import { selectMonth } from '../../features/month/monthSlice';
import { ReactComponent as BackSVG } from '../../svg/back-arrow.svg';
import { Container } from './Marathon.styled';

function Marathon() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const marathonsData = useSelector(selectMarathons);
  const month = useSelector(selectMonth);

  const handleBackToPortal = () => {
    navigate('/');
  };

  useEffect(() => {
    const date = new Date(month);
    dispatch(getMarathonsByMonth({ date: date.toISOString() }));
  }, [month]);

  return (
    <>
      <Container>
        {marathonsData && marathonsData.length > 0 ? <ViewMarathons /> : <EmptyMarathon />}
        <ToPortal>
          <ToPortalButton onClick={handleBackToPortal}>
            <BackSVG />
            <span className="text">GO BACK TO PORTAL</span>
          </ToPortalButton>
        </ToPortal>
      </Container>
    </>
  );
}

export default Marathon;
