import styled from 'styled-components';

export const Button = styled.button`
  padding-left: 10px;
  padding-right: 10px;
  height: auto;
  background-color: transparent;
`;

export const NavButton = styled(Button)`
  padding: 9px 9px 9px 9px;
  margin: 0px 3.5px 0px 3.5px;

  :hover {
    background-color: #010303;
    color: #ffffff;
  }
  background-color: transparent;
  color: #ffffff;

  svg {
    color: #ffffff;
  }

  .btn-text {
    font-size: 16px;
    align-self: center;

    &--responsive {
      @media (min-width: 320px) {
        display: none;
      }
      @media (min-width: 961px) {
        display: flex;
        margin-left: 10px;
      }
    }
    &--icon {
      margin-left: 10px;
    }
  }
`;
export const NavLabelButton = styled(Button)`
  padding: 9px 9px 9px 9px;
  margin: 0px 3.5px 0px 3.5px;

  @media (min-width: 320px) {
    border: none;
    background-color: ${props => props.varient === 'primary' && '#ffffff'};
    color: ${props => props.varient === 'primary' && '#000000'};
  }

  @media (min-width: 641px) {
    border: 1px solid #29c9de;
    background-color: ${props => (props.varient === 'primary' ? '#29c9de' : '#ffffff')};
    color: ${props => (props.varient === 'primary' ? '#ffffff' : '#000000')};
  }

  .btn-text {
    font-size: 16px;
    align-self: center;

    &--responsive {
      @media (min-width: 320px) {
        display: none;
      }
      @media (min-width: 961px) {
        display: flex;
        margin-left: 10px;
      }
    }
    &--icon {
      margin-left: 10px;
    }
  }
`;

export const ActionButton = styled(Button)`
  padding: 9px 35px 9px 35px;
  margin: 0px 3.5px 0px 3.5px;
  border: 1px solid #ebebeb;

  ${props => props.center && 'justify-content: center;'}

  :hover {
    background-color: ${props => (props.varient === 'primary' ? '#18b5ca' : '#29C9DE')};
    color: ${props => (props.varient === 'primary' ? '#ffffff' : '#ffffff')};
  }

  background-color: ${props => (props.varient === 'primary' ? '#29c9de' : '#ffffff')};
  color: ${props => (props.varient === 'primary' ? '#ffffff' : '#000000')};

  .btn-text {
    font-size: 16px;
    align-self: center;

    &--responsive {
      @media (min-width: 320px) {
        display: none;
      }
      @media (min-width: 961px) {
        display: flex;
        margin-left: 10px;
      }
    }
    &--icon {
      margin-left: 10px;
    }
  }
`;

export const OutLineButton = styled(Button)`
  padding: 5px 10px;
  display: flex;
  align-content: center;
  border: none;
  border-radius: 10px;

  color: #000000;

  :hover {
    background-color: #f1f1f1;
  }

  :disabled {
    color: #909090;

    :hover {
      background-color: transparent;
    }
  }

  background-color: ${props => (props.open ? '#f1f1f1' : 'transparent')};
  border: ${props => (props.open ? '1px solid #29C9DE' : '1px solid transparent')};
`;
