import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { useSelector, useDispatch } from 'react-redux';
import { forgotPassword, reset } from '../../features/auth/authSlice';

import AuthWrapper from '../../Components/Auth/AuthWrapper';
import { BottomBar, InputWrapper } from '../../Components/Auth/styled';
import SubmitButton from '../../Components/Auth/SubmitButton';

function ForgotPassword() {
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const { user, isError, isSuccess, message } = useSelector(state => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const emailRef = useRef();
  const [formData, setFormData] = useState({
    email: '',
  });
  const { email } = formData;

  useEffect(() => {
    function validateInputs() {
      let inputsValid = false;
      if (isFormValid() && !!email?.length) {
        inputsValid = true;
      }
      return inputsValid;
    }

    function isFormValid() {
      const valid = emailRef?.current?.validity.valid;
      return valid;
    }
    setSubmitEnabled(validateInputs());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  useEffect(() => {
    if (isError) {
      toast.error(message);
    }

    if (isSuccess || user) {
      navigate('/reset-sent');
    }

    dispatch(reset());
  }, [user, isError, isSuccess, message, navigate, dispatch]);

  const onChange = e => {
    setFormData(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const onSubmit = () => {
    dispatch(forgotPassword({ email }));
  };

  const goBack = e => {
    e.preventDefault();
    navigate(-1);
  };

  return (
    <form>
      <AuthWrapper caption="Please enter the email you signed up with">
        <InputWrapper className="gap-custom-1">
          <input
            ref={emailRef}
            type="email"
            name="email"
            placeholder="Email address"
            value={email}
            onChange={onChange}
            required
            minLength="4"
            maxLength="40"
          />
        </InputWrapper>
        <SubmitButton disabled={!submitEnabled} caption={`SEND RESET INSTRUCTIONS`} action={onSubmit} />
        <BottomBar className="gap-custom-1" onClick={goBack}>
          <span>Back</span>
        </BottomBar>
      </AuthWrapper>
    </form>
  );
}

export default ForgotPassword;
