import styled from 'styled-components';

export const ColumnContent = styled.div`
  overflow: auto;
  height: 75vh;
  padding: 0 10px 10px 10px;
`;

export const Content = styled.div`
  border-radius: 6px;
  /* background-color: rgba(248, 248, 248, 0.85); */
  background-color: #f7f7f7;
  border: 1px solid #ebebeb;
  overflow: auto;
  margin-bottom: 50px;
  @media (min-width: 641px) {
    margin-bottom: 0px;
  }
`;

export const ColumnHeader = styled.div`
  display: flex;
  margin-bottom: 5px;
  padding: 10px 0 10px 0;
  background-color: rgba(255, 255, 255, 0.9);
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  border-bottom: 1px solid #ebebeb;

  .heading {
    padding-left: 16px;
    color: #000000;
    font-weight: bold;
  }
  .sub-heading {
    color: #a1a1a1;
  }
`;

export const Wrapper = styled.div`
  margin-right: ${props => (props.right ? '8px' : '5px')};
  margin-left: ${props => (props.left ? '8px' : '5px')};
  padding-right: ${props => (props.right ? '8px' : '0')};
  grid-row: ${props => '1/' + props.count};

  overflow: auto;

  /* Mobile */
  @media (min-width: 320px) {
    width: 90vw;
  }

  /* Tablet */
  @media (min-width: 641px) {
    width: 40vw;
  }

  /* Laptop, Desktop */
  @media (min-width: 961px) {
    width: 19vw;
  }
`;
