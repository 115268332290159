import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Tasks } from '../Tasks/Tasks';
import { ColumnContent, ColumnHeader, Content, Wrapper } from './styled/Column';
import { Dates, DayBtn } from './styled/Dates';

export const WeekColumn = ({ prefix, elements, title, dates, thisMonth, total, length }) => {
  const days = Object.keys(elements);
  const dayCount = Object.keys(elements).length;
  const top = useRef(null);

  const [totalLen, setTotalLen] = useState(0);

  const CalcColumnHeight = useMemo(() => {
    if (totalLen > 7) {
      return 95 + 7 * 10 + (totalLen - 7) * 6;
    } else {
      return 95 + totalLen * 10;
    }
  }, [totalLen]);

  useEffect(() => {
    setTotalLen(total);
  }, [total]);

  return (
    <Wrapper count={5} left={parseInt(prefix) === 1} right={parseInt(prefix) === length}>
      <Dates>
        {!!dates &&
          dates.map((day, idx) => (
            <DayBtn
              thisMonth={day.month === thisMonth}
              key={idx}
              isToday={moment(`${day.day}-${day.month}-${day.year}`, 'DD-MM-YYYY').isSame(new Date(), 'day')}
            >
              {day.day}
            </DayBtn>
          ))}
      </Dates>
      <Content>
        <ColumnHeader>
          <span className="heading">
            {title} - <span className="sub-heading">{`${dayCount} ${dayCount === 1 ? 'day' : 'days'}`}</span>
          </span>
        </ColumnHeader>
        <ColumnContent id="column" ref={top}>
          <div style={{ height: `${CalcColumnHeight + 'vh'}` }}>
            {days.map((day, idx) => (
              <Tasks
                element={elements[day]}
                day={day}
                idx={idx}
                prefix={prefix}
                key={idx}
                topRef={top}
                setTotalLen={setTotalLen}
                totalLen={totalLen}
              />
            ))}
          </div>
        </ColumnContent>
      </Content>
    </Wrapper>
  );
};
