import React from 'react';
import styled from 'styled-components';
import EditSVG from '../../svg/edit.svg';
import DoneSVG from '../../svg/done.svg';
import { ReactComponent as MoveSVG } from '../../svg/drag-task.svg';
// import { Button } from "../Navbar/Navbar";

const Content = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 10px;
  justify-content: space-between;
  /* background-color: transparent; */

  .logo {
    width: 30px;
    /* height: 30px;*/
    color: white;
  }
  .logo--large {
    /* width: 45px;*/
    /* height: 45px;*/
  }
`;

const DragHandler = styled.div`
  display: flex;
  align-items: center;
`;

const Button = styled.button`
  width: 100%;
  height: 100%;
  border: none !important;
  :hover {
    background-color: transparent;
  }
`;

export const TaskHoverCard = ({ onEditCallback, onDoneCallback }) => {
  return (
    <Content>
      <Button
        style={{ background: `url(${EditSVG}) no-repeat`, backgroundPosition: 'center center' }}
        className="btn btn-outline-dark"
        onClick={e => onEditCallback()}
      >
        {/* <EditSVG className="logo" /> */}
      </Button>
      <DragHandler>
        <MoveSVG className="logo--large" />
      </DragHandler>
      <Button
        className="btn btn-outline-dark"
        style={{ background: `url(${DoneSVG}) no-repeat`, backgroundPosition: 'center center' }}
        onClick={e => onDoneCallback()}
      ></Button>
    </Content>
  );
};
