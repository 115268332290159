import styled from 'styled-components';

export const MarathonsWrapper = styled.div`
  overflow-x: auto;
  height: 70vh;
  position: relative;
`;

export const PositionWrapper = styled.div`
  position: relative;
`;

export const ColumnsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  margin: 10px 0px 0px 0px;
`;

export const DateWrapper = styled.div`
  display: inline-flex;
  flex-direction: row;
  justify-content: start;

  margin: 0px;
  position: sticky;
  bottom: 0;
  background-color: white;
  border-top: 1px solid #dadfe0;
  z-index: 120;
`;

export const DateBtn = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;

  margin: 10px 3.5px 10px 3.5px;
  min-width: 47px;
  min-height: 47px;
  width: ${props => `calc(${props.width}vw - 7px)`};
  height: ${props => `calc(${props.width}vw - 7px)`};

  font-weight: 600;
  background-color: ${props => (props.isToday ? '#626C6D' : 'transparent')};
  color: ${props => (props.isToday ? '#FFFFFF' : props.isMonth ? '#7D7D7D' : '#BCBCBC')};
  box-shadow: ${props => (props.isToday ? '0px 0px 4px rgba(0, 0, 0, 0.25)' : 'none')};
`;

export const MarathonColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: ${props => props.height}px;
`;
