export const colors = {
  mainBg: '#0C91A3',
  submitDisabled: '#86cdd6',
  formElementsColor: '#29C9DE',
  boxBg: '#FFFFFF',
  mainColor: '#000000',
  formColor: '#979797',
  inputColor: '#EDEDED',
  bottomBtnColor: '#E8FCFF',
};
