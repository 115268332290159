import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const NavbarWrapper = styled.div`
  width: 100%;
  height: 54px;
  background-color: #0b2427;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  right: 0;
  left: 0;

  z-index: 10000;
`;

export const MainWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const LogoWrapper = styled.div`
  width: 30px;
  height: 30px;
  margin: 0px 30px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const NavigationWrapper = styled.div`
  display: flex;
  align-items: center;

  .active {
    background-color: #010303;
  }
`;

export const NavButtonWrapper = styled(NavLink)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px 10px;
  margin: 0px 5px;
  border-radius: 10px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;

  background-color: #0b2427;
  color: #ffffff;
  font-size: 16px;

  :hover {
    cursor: pointer;
    background-color: #000000;
    color: #ffffff;
  }
  position: relative;
  text-decoration: none;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-color: #29c9de;
  border-radius: 5px;
  padding: 5px;
`;

export const NavLabelContainer = styled.span`
  margin-left: 10px;
`;

export const MonthSelectorWrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0d4d56;
  color: #ffffff;
  border-radius: 10px;

  text-align: center;

  @media (min-width: 320px) {
    width: 50vw;
  }

  @media (min-width: 961px) {
    width: 20vw;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
  }

  .month {
    margin: 0px 10px;
  }
`;

export const MonthButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  margin: 2px;
  background-color: transparent;

  :hover {
    background-color: #0c3a41;
  }
`;

export const ActionsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
