import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { useSelector, useDispatch } from 'react-redux';
import { login, reset } from '../../features/auth/authSlice';
import { selectSession, toggleSession } from '../../features/auth/session/sessionSlice';

import AuthWrapper from '../../Components/Auth/AuthWrapper';
import CheckBoxSection, { ActionText } from '../../Components/Auth/Check';
import SubmitButton from '../../Components/Auth/SubmitButton';
import { ActionsSection, BottomBar, InputWrapper } from '../../Components/Auth/styled/';
import useAuth from '../../features/auth/hooks/useAuth';

function Login() {
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const { isAuthenticated } = useAuth();
  const { user, isLoading, isError, isSuccess, message } = useSelector(state => state.auth);
  const keepSignedIn = useSelector(selectSession);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const emailRef = useRef();
  const passwordRef = useRef();
  const submitCaption = isLoading ? 'Loading...' : 'Log In';
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  // const { email, password } = formData;

  useEffect(() => {
    function validateInputs() {
      let inputsValid = false;
      if (isFormValid() && !!formData.email?.trim()?.length && !!formData.password?.length) {
        inputsValid = true;
      }
      return inputsValid;
    }

    function isFormValid() {
      const valid = emailRef?.current?.validity.valid;
      return valid;
    }
    setSubmitEnabled(validateInputs());
  }, [formData.email, formData.email.length, formData.password?.length]);

  useEffect(() => {
    if (isError) {
      toast.error(message);
    }

    if (isAuthenticated && user) {
      if (user.onboarding === false) {
        navigate('/onboarding');
      } else {
        navigate('/');
      }
    }

    dispatch(reset());
  }, [user, isError, message, navigate, dispatch, isAuthenticated]);

  const onChange = e => {
    setFormData(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  const onSubmit = () => {
    if (!submitEnabled) {
      toast.error("Email and password can't be empty");
    } else {
      dispatch(login({ email: formData.email, password: formData.password, keepSignedIn }));
    }
  };

  const gotoRegister = e => {
    e.preventDefault();
    navigate('/register');
  };

  const saveToLocalStorage = () => {
    dispatch(toggleSession());
  };
  useEffect(() => {
    emailRef.current.focus();
  }, []);

  return (
    <AuthWrapper caption="Login below">
      <InputWrapper className="gap-custom-1">
        <input
          autoComplete="off"
          ref={emailRef}
          type="email"
          name="email"
          placeholder="Email address"
          value={formData.email}
          onChange={onChange}
          required
          minLength="4"
          maxLength="40"
        />
        {/* <input type="email" name="email" placeholder="Email address" value={email} onChange={onChange} required minLength="4" maxLength="40" /> */}
      </InputWrapper>
      <InputWrapper className="gap-custom-1">
        <input
          autoComplete="off"
          ref={passwordRef}
          type="password"
          name="password"
          placeholder="Password"
          value={formData.password}
          onChange={onChange}
          required
          maxLength="40"
        />
      </InputWrapper>
      <ActionsSection className="gap-custom-1">
        <CheckBoxSection title="Keep me signed in" action={saveToLocalStorage} isActive={keepSignedIn} />
        <Link style={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }} to={`/forgot-password`}>
          <ActionText>Forgot Password?</ActionText>
        </Link>
      </ActionsSection>
      <SubmitButton caption={submitCaption} action={onSubmit} />
      <BottomBar onClick={gotoRegister} className="gap-custom-1">
        <span>Create an Account</span>
      </BottomBar>
    </AuthWrapper>
  );
}

export default Login;
