import styled from 'styled-components';

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  .title-box {
    width: auto;
    @media (min-width: 320px) {
      margin: 20px 20px 10px 45px;
    }
    @media (min-width: 961px) {
      margin: 20px 100px 10px 45px;
    }
  }
`;

export const ProgressInputWrapper = styled.div`
  display: flex;
  /* width: 100%; */
  margin-bottom: 20px;

  svg {
    top: 0;
    min-width: 20px;
    min-height: 20px;
    width: 20px;
    height: 20px;

    margin: 10px 10px 10px 10px;
  }

  .input-box {
    width: auto;
    margin: 0 5px;

    &--l {
      margin-left: 5px;
    }
    &--r {
      margin-right: 5px;
    }
  }

  .text {
    margin-top: 10px;
    white-space: nowrap;
  }
`;

export const DateInputWrapper = styled.div`
  display: flex;
  /* gap: 20px; */
  justify-content: start;
  align-items: center;
  margin-bottom: 10px;

  svg {
    min-width: 20px;
    min-height: 20px;
    width: 20px;
    height: 20px;
    margin: 10px;
  }

  .text {
    margin: 0 5px;
  }

  .ant-picker-clear {
    background: transparent !important;
  }

  .ant-picker-focused {
    background-color: #f1f1f1 !important;
    border: 1px solid #29c9de !important;
  }

  .ant-picker-input-placeholder > input {
    color: #000000 !important;
  }

  .picker {
    height: auto;
    padding: 5px 10px;
    display: flex;
    align-content: center;
    border: none;
    border-radius: 10px;

    color: #000000;

    :hover {
      background-color: #f1f1f1 !important;
    }

    :disabled {
      color: #909090;

      :hover {
        background-color: transparent;
      }
    }
  }
`;

export const AllDayWrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  margin: 0 0 25px 50px;

  .text {
    margin-left: 10px;
  }
`;

export const TaskSelectWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-right: 100px;
  svg {
    min-width: 20px;
    min-height: 20px;
    width: 20px;
    height: 20px;

    margin: 10px 10px 10px 10px;
  }
`;

export const UpdateBanner = styled.div`
  height: 50px;
  width: 100%;
  background-color: ${props => props.bgColor};
  display: flex;

  .box-shadow {
    box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.09);
    display: flex;
    width: 100%;
    height: 100%;
  }
`;

export const UpdateContent = styled.div`
  display: flex;
  align-items: center;

  width: 100%;

  @media (min-width: 320px) {
    margin: 0px 20px 0 20px;
  }
  @media (min-width: 961px) {
    margin: 0px 20px 0 50px;
  }
`;

export const UpdateHeader = styled.span`
  color: #ffffff;
  font-size: 16px;
  width: 60%;
`;

export const UpdateInput = styled.div`
  width: 40%;
  display: flex;
  align-items: center;

  .number-input {
    width: 80px;
  }

  .text {
    display: inline-block;
    text-overflow: ellipsis;
    max-height: 40px;
    max-width: calc(100%);
    white-space: nowrap;
    overflow: hidden;
    color: #ffffff;
    font-size: 14px;
    margin: 0 10px;
  }
`;

export const ProgressLineWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 961px) {
    flex-direction: row;
    width: 70%;
    ${props => props.onboarding && `flex-direction: column; width: 70%;`}
  }
`;

export const ProgressLine = styled.div`
  display: flex;
  justify-content: start;
  /* width: ${props => props.width}; */

  margin-top: ${props => (props.margin ? '10px' : '0px')};
  width: calc(${props => props.width} - 10%);

  @media (min-width: 961px) {
    margin-top: 0px;
    width: ${props => props.width}%;
  }
`;
