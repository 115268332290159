import styled from 'styled-components';

const ActionsSection = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  &.vertical {
    flex-direction: column;
  }
`;

export default ActionsSection;
