import React from 'react';
import PropTypes from 'prop-types';
import { LabelPopoverClose } from '../LabelsDropdown/styled/LabelsDropdown.styled';
import { ProfilePopOverContentBar } from './styled/ProfileDropdown.styled';
import { useDispatch } from 'react-redux';
import { logout } from '../../features/auth/authSlice';
import { useNavigate } from 'react-router-dom';

const ProfileDropdown = ({ setShowProfileDropdown, isMobile }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const profileMenus = [
    {
      label: 'Help & FAQ',
      func: () => {
        window.location = 'https://www.bilalpervez.com/mastershop-help';
      },
    },
    {
      label: 'Log Out',
      func: () => {
        dispatch(logout());
        navigate('/login');
      },
    },
  ];
  return (
    <div>
      {isMobile && (
        <div style={{ width: '296px', display: 'flex', justifyContent: 'flex-end' }}>
          <LabelPopoverClose onClick={() => setShowProfileDropdown(false)} />
        </div>
      )}
      {profileMenus.map(menu => (
        <ProfilePopOverContentBar key={menu.label} onClick={menu.func}>
          {menu.label}
        </ProfilePopOverContentBar>
      ))}
    </div>
  );
};

ProfileDropdown.propTypes = {};

export default ProfileDropdown;
