/*
our data structure 
    { 
        _id: mongooseId , 
        title:task Title - String ,
        date: date of task - Date , 
        week- week of task - string ,
        month:month of task -String 
    } 
*/
import Moment from "moment";
import { extendMoment } from "moment-range";

const moment = extendMoment(Moment);

moment.updateLocale("en", {
  week: {
    dow: 1, // Monday is the first day of the week.
  },
});

function getWeekOfMonth(date) {
  let weekInYearIndex = date.week();
  if (date.year() !== date.weekYear()) {
    weekInYearIndex = date.clone().subtract(1, "week").week() + 1;
  }
  const weekIndex = weekInYearIndex - moment(date).startOf("month").week() + 1;
  return weekIndex;
}

export const refactorTasksData = (tasks, month = 1, year = 2022) => {
  // let mainData = {};

  // const startMonth = moment(`${year}-${month}`, "YYYY-MM"); // month zero indexed
  // const fDay = moment(startMonth).clone().startOf("month");
  // const eDay = moment(startMonth).clone().endOf("month");

  // const monthRange = moment.range(fDay, eDay);
  // const weeks = [];

  // let weekIdx = 1;
  // Array.from(monthRange.by("day")).forEach((it) => {
  //   if (!weeks.includes(it.week())) {
  //     weeks.push(it.week());
  //     mainData = {
  //       ...mainData,
  //       [`${it.week()}-week`]: {
  //         index: weekIdx,
  //         title: `Week ${weekIdx}`,
  //         data: {},
  //       },
  //     };
  //     weekIdx++;
  //   }
  // });

  // weeks.forEach((week, idx) => {
  //   let tempMonth = month;
  //   let tempYear = year;

  //   if (month === 12 && week === 1) {
  //     tempYear = year + 1;
  //     tempMonth = 1;
  //   }

  //   const firstWeekDay = moment(`${tempYear}-${tempMonth}`, "YYYY-MM")
  //     .clone()
  //     .week(week)
  //     .startOf("week");

  //   const lastWeekDay = moment(`${tempYear}-${tempMonth}`, "YYYY-MM")
  //     .clone()
  //     .week(week)
  //     .endOf("week");

  //   const weekRange = moment.range(firstWeekDay, lastWeekDay);

  //   Array.from(weekRange.clone().by("days")).forEach((d) => {
  //     if (d.clone().month() + 1 === month) {
  //       mainData = {
  //         ...mainData,
  //         [`${week}-week`]: {
  //           ...mainData[`${week}-week`],
  //           data: {
  //             ...mainData[`${week}-week`]["data"],
  //             [d.clone().date()]: {
  //               date: d.toDate().toISOString(),
  //               data: [],
  //             },
  //           },
  //         },
  //       };
  //     }
  //   });
  // });

  const m = moment(`${year}-${month}`, "YYYY-MM"); // month zero indexed
  const startDate = m.clone().startOf("month").startOf("week");
  const endDate = m.clone().endOf("month");

  const day = startDate.clone().subtract(1, "day");

  let mainData = Object.assign({});

  // let days = {};
  let weekIdx = 1;
  while (day.isBefore(endDate, "day")) {
    const weekDates = Array(7)
      .fill(0)
      .map(() => {
        day.add(1, "day");
        return {
          date: day.clone().toDate().toISOString(),
          day: day.clone().format("D"),
          year: day.clone().year(),
          month: day.clone().month() + 1,
          data: [],
        };
      })
      .filter((d) => d.month === month);

    mainData = {
      ...mainData,
      [weekIdx]: {
        ...mainData[weekIdx],
        index: weekIdx,
        title: `Week ${weekIdx}`,
        total: weekDates.length,
        data: {
          // ...mainData[weekIdx]["data"],
          ...weekDates.reduce((a, v) => ({ ...a, [v.day]: v }), {}),
        },
      },
    };
    weekIdx++;
  }

  for (const task of tasks) {
    const date = moment(new Date(task.date).toISOString());
    // const currWeek = date.week();
    const currWeek = getWeekOfMonth(date);
    const currDate = date.clone().date();

    // console.log(task.date, currWeek, currDate);

    if (
      // weeks.includes(currWeek) &&
      mainData.hasOwnProperty(currWeek) &&
      mainData[currWeek].data.hasOwnProperty(currDate)
    ) {
      mainData[currWeek] = {
        ...mainData[currWeek],
        total:
          mainData[currWeek]["data"][currDate]["data"].length > 0
            ? mainData[currWeek]["total"] + 1
            : mainData[currWeek]["total"],
        data: {
          ...mainData[currWeek]["data"],
          [currDate]: {
            ...mainData[currWeek]["data"][currDate],
            data: [...mainData[currWeek]["data"][currDate]["data"], task].sort(
              (a, b) => {
                return a.index - b.index;
              }
            ),
          },
        },
      };
    }
  }

  return mainData;
};
