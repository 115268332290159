import React from 'react';
import styled from 'styled-components';
import { ActionButton, Title } from './Onboarding.styled';
import { ReactComponent as LabelsSVG } from '../../../svg/labels.svg';
import { ReactComponent as ShuffleIconSVG } from '../../../svg/shuffle-icon.svg';
import { ReactComponent as MarathonIconSVG } from '../../../svg/marathon-icon.svg';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

const DataWrapper = styled.div`
  width: 100%;
`;

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  justify-content: start;
  align-items: center;
`;

const IconWrapper = styled.div`
  padding: 15px;
  border: 0.68px solid #29c9de;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;

  svg {
    width: 25px;
    height: 25px;
  }
`;

const TextWrapper = styled.div`
  .text {
    font-size: 20px;
    color: #5a5a5a;

    .b {
      font-size: 20px;
      font-weight: 700;
    }
  }
`;

const FinishStatus = ({ icon, text }) => {
  return (
    <Wrapper>
      <IconWrapper>{icon}</IconWrapper>
      <TextWrapper>{text}</TextWrapper>
    </Wrapper>
  );
};

export const StepComplete = ({ onboardingData, onFinish }) => {
  return (
    <Container>
      <Title>Setup complete</Title>
      <DataWrapper>
        {onboardingData['label'].status === 'done' && (
          <FinishStatus
            icon={<LabelsSVG />}
            text={<span className="text">+{onboardingData['label'].count} labels added</span>}
          />
        )}
        {onboardingData['task'].status === 'done' && (
          <FinishStatus
            icon={<ShuffleIconSVG />}
            text={
              <span className="text">
                +{onboardingData['task'].count} task added to <span className="b">Shuffle</span>
              </span>
            }
          />
        )}
        {onboardingData['goal'].status === 'done' && (
          <FinishStatus
            icon={<MarathonIconSVG />}
            text={
              <span className="text">
                +{onboardingData['goal'].count} goal added to <span className="b">Marathons</span>
              </span>
            }
          />
        )}
      </DataWrapper>
      <ActionButton onClick={onFinish}>FINISH</ActionButton>
    </Container>
  );
};
