import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const initialState = {
  value: moment(new Date()).toISOString(),
  status: "idle",
};

export const monthSlice = createSlice({
  name: "month",
  initialState,

  reducers: {
    increment: (state) => {
      const nextMonth = moment(state.value).add(1, "months").toISOString();
      // console.log(state.value);
      state.value = nextMonth;
      // state = {
      //   ...state,
      //   value: nextMonth,
      // };
    },
    decrement: (state) => {
      const prevMonth = moment(state.value).subtract(1, "months");
      state.value = prevMonth.toISOString();
    },
    goToMonth: (state, action) => {
      const newMonth = moment(action.payload);
      state.value = newMonth.toISOString();
    },
  },
});

export const { increment, decrement, goToMonth } = monthSlice.actions;

export const selectMonth = (state) => state.month.value;

export default monthSlice.reducer;
