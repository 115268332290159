import React, { useLayoutEffect, useState } from 'react';

import {
  LabelAddButton,
  LabelEditButton,
  LabelEditIcon,
  LabelPopoverClose,
  LabelsPopOverContentBar,
  LabelsPopOverContentTypeColor,
  LabelsPopOverContentTypeCount,
  LabelsPopOverContentTypeText,
  LabelsPopOverTitle,
  LabelsPopOverTitleDescription,
} from './styled/LabelsDropdown.styled';
import { useDispatch, useSelector } from 'react-redux';
import { getLabels, selectLabels } from '../../features/labels/labelsSlice';
import {
  getFilter,
  getTasksByMonth,
  getTotalCount,
  selectTasks,
  selectTasksCount,
  setFilter,
} from '../../features/tasks/tasksSlice';
import { selectMonth } from '../../features/month/monthSlice';
import moment from 'moment';
import './LabelsDropdown.css';
import { selectMarathons } from '../../features/marathon/marathonSlice';

const LabelsDropdown = ({ setShowLabels, setShowAddLabel, setShowEditLabel, setLabel, isMobile, type }) => {
  const labels = useSelector(selectLabels);
  const tasks = useSelector(selectTasks);
  const marathons = useSelector(selectMarathons);
  const selectedMonth = useSelector(selectMonth);
  const tasksCount = useSelector(selectTasksCount);
  const totalTasksCount = useSelector(getTotalCount);

  const filter = useSelector(getFilter);
  const { user } = useSelector(state => state.auth);
  const [notAvailableCount, setNotAvailableCount] = useState(0);
  const [naSelected, setNaSelected] = useState(false);
  const [labelIdsSelected, setLabelIdsSelected] = useState([]);
  const [totalSelectedTasksCount, setTotalSelectedTasksCount] = useState(0);
  const [labelCounts, setLabelCounts] = useState({});
  const [marathonCounts, setMarathonCounts] = useState({});
  const dispatch = useDispatch();

  const onClickLabel = labelId => {
    let newSelectedLabelIds = [];
    if (labelIdsSelected.includes(labelId)) {
      newSelectedLabelIds = labelIdsSelected.filter(lid => lid !== labelId);
    } else {
      newSelectedLabelIds = [...labelIdsSelected, labelId];
    }
    setLabelIdsSelected(newSelectedLabelIds);
  };

  useLayoutEffect(() => {
    let labeledTasksSum = 0;
    labels &&
      labels.forEach(label => {
        labeledTasksSum += label.taskIds.length;
      });
    setNotAvailableCount(totalTasksCount - labeledTasksSum);
    setTotalSelectedTasksCount(tasksCount);
    if (type === 'kalender') {
      const labelCountsLocal = {};
      tasks.forEach(task => {
        if (task.labelId === null) {
          if (labelCountsLocal.hasOwnProperty('unavailable')) {
            labelCountsLocal['unavailable'] = ++labelCountsLocal['unavailable'];
          } else {
            labelCountsLocal['unavailable'] = 1;
          }
        } else {
          if (labelCountsLocal.hasOwnProperty(task.labelId)) {
            labelCountsLocal[task.labelId] = ++labelCountsLocal[task.labelId];
          } else {
            labelCountsLocal[task.labelId] = 1;
          }
        }
      });
      setLabelCounts(labelCountsLocal);
    } else {
      const marathonCountsLocal = {};
      marathons.forEach(marathon => {
        if (marathon.labelId === null) {
          if (marathonCountsLocal.hasOwnProperty('unavailable')) {
            marathonCountsLocal['unavailable'] = ++marathonCountsLocal['unavailable'];
          } else {
            marathonCountsLocal['unavailable'] = 1;
          }
        } else {
          if (marathonCountsLocal.hasOwnProperty(marathon.labelId)) {
            marathonCountsLocal[marathon.labelId] = ++marathonCountsLocal[marathon.labelId];
          } else {
            marathonCountsLocal[marathon.labelId] = 1;
          }
        }
      });
      setMarathonCounts(marathonCountsLocal);
    }
  }, [labels, tasks, tasksCount, totalTasksCount, selectedMonth, marathons]);

  useLayoutEffect(() => {
    dispatch(getLabels(user._id));
  }, [dispatch, tasks, user._id, selectedMonth]);

  useLayoutEffect(() => {
    if (type === 'kalender') {
      dispatch(getTasksByMonth(filter));
    }
  }, [tasksCount, selectedMonth]);

  useLayoutEffect(() => {
    const m = moment(selectedMonth);
    const filterCriteria = { ...filter, month: m.month() + 1, year: m.year(), naSelected: false, labelIds: [] };
    if (naSelected) {
      filterCriteria.naSelected = true;
    } else {
      delete filterCriteria.naSelected;
    }
    if (labelIdsSelected.length > 0) {
      filterCriteria.labelIds = labelIdsSelected;
    } else {
      delete filterCriteria.labelIds;
    }
    dispatch(setFilter(filterCriteria));
    dispatch(getTasksByMonth(filterCriteria));
  }, [selectedMonth, labelIdsSelected, naSelected, dispatch]);

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'base-line',
          marginBottom: '9px',
          marginTop: '12px',
        }}
      >
        <LabelsPopOverTitle>Labels</LabelsPopOverTitle>
        {type === 'kalender' ? (
          <LabelsPopOverTitleDescription>
            showing {totalSelectedTasksCount} out of {totalTasksCount} tasks
          </LabelsPopOverTitleDescription>
        ) : (
          <LabelsPopOverTitleDescription>
            {/* showing {totalSelectedTasksCount} out of {totalTasksCount} tasks */}
          </LabelsPopOverTitleDescription>
        )}

        {isMobile && (
          <LabelPopoverClose
            onClick={() => {
              console.log('hets');
              setShowLabels(false);
            }}
          />
        )}
      </div>
      <div key={Date.now()} style={{ display: 'flex', alignItems: 'center' }}>
        <LabelsPopOverContentBar
          backgroundColor={naSelected ? '#eafafc' : '#f4f8f8'}
          onClick={() => setNaSelected(!naSelected)}
        >
          <LabelsPopOverContentTypeColor color="#656F6E" />
          <LabelsPopOverContentTypeText>N/A</LabelsPopOverContentTypeText>
          {type === 'kalender' ? (
            <LabelsPopOverContentTypeCount>
              {labelCounts['unavailable'] < 0 ? 0 : labelCounts['unavailable']}
            </LabelsPopOverContentTypeCount>
          ) : (
            <LabelsPopOverContentTypeCount>
              {marathonCounts['unavailable'] < 0 ? 0 : marathonCounts['unavailable']}
            </LabelsPopOverContentTypeCount>
          )}
        </LabelsPopOverContentBar>
      </div>
      {labels &&
        labels.map(label => (
          <div key={label._id} style={{ display: 'flex', alignItems: 'center' }}>
            <LabelsPopOverContentBar
              backgroundColor={labelIdsSelected.includes(label._id) ? '#eafafc' : '#f4f8f8'}
              onClick={() => onClickLabel(label._id)}
            >
              <LabelsPopOverContentTypeColor color={label.color} />
              <LabelsPopOverContentTypeText>{label.title}</LabelsPopOverContentTypeText>
              {type === 'kalender' ? (
                <LabelsPopOverContentTypeCount>{labelCounts[label._id]}</LabelsPopOverContentTypeCount>
              ) : (
                <LabelsPopOverContentTypeCount>{marathonCounts[label._id]}</LabelsPopOverContentTypeCount>
              )}
            </LabelsPopOverContentBar>
            <LabelEditButton
              onClick={() => {
                setLabel(label);
                // setShowLabels(false);
                setShowEditLabel(true);
              }}
            >
              <LabelEditIcon />
            </LabelEditButton>
          </div>
        ))}
      <LabelAddButton
        onClick={() => {
          setShowAddLabel(true);
        }}
      >
        + Add
      </LabelAddButton>
    </>
  );
};

LabelsDropdown.propTypes = {};

export default LabelsDropdown;
