import React, { useCallback, useState } from 'react';
import { ActionButton } from '../../Button/Button';
import AddMarathonPopup from '../AddMarathon/AddMarathonPopup';
import { Action, AddSvg, Placeholder, Wrapper } from './styled/EmptyMarathon.styled';

function EmptyMarathon() {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleClose = useCallback(e => {
    setIsOpen(false);
  }, []);

  return (
    <>
      <Wrapper>
        <Placeholder>
          For goals that take longer than a day to finish, add <span className="bold">month-long Marathons.</span>
        </Placeholder>
        <Action>
          <ActionButton
            varient="primary"
            className="btn d-flex"
            style={{ paddingLeft: '100px', paddingRight: '100px' }}
            onClick={handleOpen}
          >
            <AddSvg />
            <span className="btn-text btn-text--icon">Add a Marathon</span>
          </ActionButton>
        </Action>
      </Wrapper>

      <AddMarathonPopup isOpen={isOpen} mode="add" onClose={handleClose} />
    </>
  );
}

export default EmptyMarathon;
