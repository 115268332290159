import React from 'react';
import styled from 'styled-components';

const StepperWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Step = styled.button`
  display: flex;
  background-color: ${props => (props.isSelected ? '#29C9DE' : '#D9D9D9')};
  height: 12px;
  width: 12px;
  padding: 0px;
  margin: 5px;
  border: none;
  border-radius: 50%;
`;

export const Stepper = ({ stepperAction, steps, page }) => {
  return (
    <StepperWrapper>
      {steps.map((step, idx) => (
        <Step key={idx} isSelected={step.index === page} onClick={() => stepperAction(step.index)} />
      ))}
    </StepperWrapper>
  );
};
