import React from 'react';
import { ReactComponent as LogoSVG } from '../../../svg/logo.svg';
import styled from 'styled-components';
import { Button } from '../../../Components/Button/Button';
import { ActionButton, SubTitle, Title } from './Onboarding.styled';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Logo = styled(LogoSVG)`
  width: 100px;
  height: 100px;
  color: #29c9de;
`;

const SkipButton = styled(Button)`
  padding: 9px 35px 9px 35px;
  margin-top: 5px;
  width: 100%;
  border: none;
  color: #5a5a5a;
  font-size: 14px;
  font-weight: 400;
  align-self: center;
  :hover {
    color: #5a5a5a;
  }
`;

export const StepWelcome = ({ onNext, onSkip }) => {
  return (
    <Container>
      <Logo />
      <Title>Welcome to Mastershop</Title>
      <SubTitle>A productivity tool for achievers</SubTitle>
      <ActionButton onClick={() => onNext({ status: 'done' }, 'welcome')}>BEGIN SETUP</ActionButton>
      <SkipButton onClick={() => onSkip()}>Skip - I know how to use Mastershop</SkipButton>
    </Container>
  );
};
