import styled from 'styled-components';
import { colors } from '../colors';

const BottomBar = styled.div`
  height: 63px;
  width: 100%;
  cursor: pointer;
  user-select: none;
  max-width: 100%;
  background-color: ${colors.bottomBtnColor};
  display: grid;
  place-content: center;

  margin-bottom: 10px;
`;

export default BottomBar;
