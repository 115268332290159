import styled from 'styled-components';
import { ReactComponent as SettingsSVG } from '../../../svg/settings.svg';
import { ReactComponent as PlusSVG } from '../../../svg/plus.svg';
import { ReactComponent as LabelEditSVG } from '../../../svg/label-edit.svg';
import { ReactComponent as LabelPopoverCloseSVG } from '../../../svg/label-popover-close.svg';
import { ReactComponent as LabelBackArrowSVG } from '../../../svg/label-back-arrow.svg';
import { ReactComponent as LabelColorSelectTickSVG } from '../../../svg/label-color-select-tick.svg';

export const Bar = styled.header`
  width: 100%;
  height: 54px;
  background-color: #ffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  right: 0;
  left: 0;

  .logo {
    flex-direction: column;
    margin-left: 20px;
  }

  box-shadow: 0px 2px 7px 0 rgb(0 0 0 / 20%), 0px 2px 20px 0 rgb(0 0 0 / 19%);
`;

export const Logo = styled.div`
  @import url('https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap');
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 10px 0px 10px 20px;
  cursor: pointer;

  svg {
    width: 20px;
    height: 20px;
  }

  .title {
    font-size: 18px;
    font-family: 'Noto Sans', sans-serif;
  }

  @media (min-width: 320px) {
    display: none;
  }

  @media (min-width: 961px) {
    display: flex;
  }
`;

export const MonthSelector = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;

  .month {
    font-size: 18px;
    font-weight: 600;
    margin-left: 20px;
    margin-right: 20px;

    @media (min-width: 320px) {
      width: 100%;
    }

    @media (min-width: 641px) {
      width: 200px;
    }
  }

  .btn-month {
    background-color: transparent;
    border: none;
  }
`;

// export const SettingsBtn = styled.div`
//   margin-right: 20px;
//   margin: 5px;

//   .btn-text {
//     @media (min-width: 320px) {
//       display: none;
//     }
//     @media (min-width: 961px) {
//       display: flex;
//     }
//   }
// `;

export const Settings = styled(SettingsSVG)`
  height: 22.8px;
  width: 23.15px;
`;

export const ToolsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  .blue-btn {
    background-color: #29c9de;
    color: #ffffff;

    :hover {
      background-color: #18b5ca;
      color: #ffffff;
    }
  }
`;

export const AddSvg = styled(PlusSVG)`

  align-self: center;
  margin: 5px;
`;

// start remove from here add label

// end remove from here add label
