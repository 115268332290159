import moment from 'moment-timezone';
import http from '../utils/http';

export async function createMarathonAPI(payload) {
  try {
    const response = await http.post('/api/marathons', payload);
    return response.data;
  } catch (error) {
    // return error;
  }
}

export async function getMarathonsByMonthAPI(date) {
  const tz = moment.tz.guess();
  try {
    const response = await http.get(`/api/marathons/date/${date}?timezone=${tz}`);
    return response.data;
  } catch (error) {
    // return error;
  }
}

export async function getMarathonsForDayApi(date) {
  const tz = moment.tz.guess();
  try {
    const response = await http.get(`/api/marathons/dashboard/date/${date}?timezone=${tz}`);
    return response.data;
  } catch (error) {
    // return error;
  }
}

export async function getAllMarathonsAPI() {
  try {
    const response = await http.get(`/api/marathons`);
    return response.data;
  } catch (error) {
    // return error;
  }
}

export async function getMarathonByIdAPI(id) {
  try {
    const response = await http.get(`/api/marathons/${id}`);
    return response.data;
  } catch (error) {
    // return error;
  }
}

export async function updateMarathonAPI(id, payload) {
  try {
    const response = await http.patch(`/api/marathons/${id}`, payload);
    return response.data;
  } catch (error) {
    // return error;
  }
}

export async function updateMarathonProgressAPI(id, payload) {
  try {
    const response = await http.patch(`/api/marathons/progress/${id}`, payload);
    return response.data;
  } catch (error) {
    // return error;
  }
}

export async function deleteMarathonAPI(id) {
  try {
    const response = await http.delete(`/api/marathons/${id}`);
    return response.data;
  } catch (error) {
    // return error;
  }
}
