import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useSelector, useDispatch } from 'react-redux';
import { register, reset } from '../../features/auth/authSlice';
import { ActionsSection, BottomBar, InputWrapper } from '../../Components/Auth/styled/';
import AuthWrapper from '../../Components/Auth/AuthWrapper';
import CheckBoxSection from '../../Components/Auth/Check';
import SubmitButton from '../../Components/Auth/SubmitButton';

function Register() {
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [receivePromos, setReceivePromos] = useState(false);
  const { user, isLoading, isError, isSuccess, message } = useSelector(state => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const emailRef = useRef();
  const submitCaption = isLoading ? 'Loading...' : 'SIGN UP';
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    firstName: '',
    lastName: '',
    confirmPassword: '',
  });
  const { email, password, firstName, lastName, confirmPassword } = formData;

  useEffect(() => {
    function validateInputs() {
      let inputsValid = false;
      confirmPassword && setPasswordMatch(password === confirmPassword);
      if (
        emailValid() &&
        !!email?.length &&
        !!password?.length &&
        firstName?.length &&
        lastName?.length &&
        password === confirmPassword &&
        termsAccepted
      ) {
        inputsValid = true;
      }
      return inputsValid;
    }

    function emailValid() {
      const valid = emailRef?.current?.validity.valid;
      return valid;
    }
    setSubmitEnabled(validateInputs());
  }, [formData, termsAccepted]);

  useEffect(() => {
    if (isError) {
      toast.error(message);
    }

    if (isSuccess || user) {
      if (user.onboarding === false) {
        navigate('/onboarding');
      } else {
        navigate('/');
      }
    }

    dispatch(reset());
  }, [user, isError, isSuccess, message, navigate, dispatch]);

  const onChange = e => {
    setFormData(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const onSubmit = () => {
    dispatch(register({ email, password, firstName, lastName, keepSignedIn: false, getPromos: receivePromos }));
  };

  const gotoLogin = e => {
    e.preventDefault();
    navigate('/login');
  };

  const setTerms = () => {
    setTermsAccepted(!termsAccepted);
  };
  const setPromos = () => {
    setReceivePromos(!receivePromos);
  };

  return (
    <AuthWrapper caption="Simple & effective task management" type="register">
      <div className="gap-custom-1" style={{ display: 'flex', width: '100%' }}>
        <InputWrapper>
          <input
            name="firstName"
            placeholder="First name"
            value={firstName}
            onChange={onChange}
            required
            minLength="4"
            maxLength="60"
          />
        </InputWrapper>
        <div style={{ width: 0, marginRight: '5px', marginLeft: '5px' }}></div>
        <InputWrapper>
          <input
            name="lastName"
            placeholder="Last name"
            value={lastName}
            onChange={onChange}
            required
            minLength="4"
            maxLength="60"
          />
        </InputWrapper>
      </div>
      <InputWrapper className="gap-custom-1">
        <input
          ref={emailRef}
          type="email"
          name="email"
          placeholder="Email address"
          value={email}
          onChange={onChange}
          required
          minLength="4"
          maxLength="40"
        />
      </InputWrapper>
      <div className="gap-custom-1" style={{ display: 'flex', width: '100%' }}>
        <InputWrapper>
          <input
            type="password"
            name="password"
            placeholder="Password"
            value={password}
            onChange={onChange}
            required
            maxLength="40"
          />
        </InputWrapper>
        <div style={{ width: 0, marginRight: '5px', marginLeft: '5px' }}></div>
        <InputWrapper>
          <input
            type="password"
            name="confirmPassword"
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={onChange}
            required
            maxLength="40"
          />
        </InputWrapper>
      </div>
      {!passwordMatch && <div className="gap-custom-password-mismatch"> Those passwords don’t match. Try again.</div>}
      <ActionsSection className="gap-custom-1 vertical">
        <CheckBoxSection
          margin="15px"
          title="I agree to the Terms and Conditions"
          action={setTerms}
          isActive={termsAccepted}
        />

        <CheckBoxSection
          margin="15px"
          title="I want to receive mail with productivity tips and promotions"
          action={setPromos}
          isActive={receivePromos}
        />
      </ActionsSection>
      <SubmitButton disabled={!submitEnabled} caption={submitCaption} action={onSubmit} />
      <BottomBar className="gap-custom-1" onClick={gotoLogin}>
        <span>Already have an account? Log In</span>
      </BottomBar>
    </AuthWrapper>
  );
}

export default Register;
