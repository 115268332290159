// a little function to help us with reordering the result
export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Moves an item from one list to another list.
 */
export const move = (source, destination, sourceIdx, sourceKey, destinationIdx, destinationKey, destinationDate) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(sourceIdx, 1);

  destClone.splice(destinationIdx, 0, { ...removed, date: destinationDate });

  const result = {};
  result[sourceKey] = sourceClone.map((ele, idx) => {
    return {
      ...ele,
      index: idx,
    };
  });
  result[destinationKey] = destClone.map((ele, idx) => {
    return {
      ...ele,
      index: idx,
    };
  });

  return result;
};
