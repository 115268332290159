import styled from 'styled-components';
export const ProfileDropdownButton = styled.div`
  background: #454545;
  border-radius: 999px;
  height: 36px;
  width: 36px;
  margin-right: 24px;

  @media (min-width: 320px) {
    margin-right: 19px;
  }

  font-family: 'SFPRODISPLAYREGULAR';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */

  letter-spacing: 0.38px;

  color: #29c9de;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const ProfilePopOverContentBar = styled.div`
  width: 296px;
  height: 46px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  :hover {
    background: #eafafc;
  }
  cursor: pointer;
  padding-left: 13px;

  font-family: 'MYRIADPRO-SEMIBOLD';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  /* identical to box height */

  color: #000000;
`;
