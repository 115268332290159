import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createLabelApi, deleteLabelApi, getAllLabels, updateLabelApi } from './labelsAPI';

const initialState = {
  value: [],
  status: 'idle',
};

export const getLabels = createAsyncThunk('labels/fetchLabels', async userId => {
  const response = await getAllLabels(userId);

  return response;
});

// create label api
export const createLabel = createAsyncThunk('labels/createLabel', async label => {
  const response = await createLabelApi(label);
  // The value we return becomes the `fulfilled` action payload
  return response;
});
// delete label api
export const deleteLabel = createAsyncThunk('labels/deleteLabel', async labelId => {
  const response = await deleteLabelApi(labelId);
  return response;
});
// update label api
export const updateLabel = createAsyncThunk('labels/updateLabel', async newLabel => {
  const response = await updateLabelApi(newLabel);
  return response;
});

export const labelsSlice = createSlice({
  name: 'labels',
  initialState,

  extraReducers: builder => {
    builder
      .addCase(getLabels.pending, state => {
        state.status = 'loading';
      })
      .addCase(getLabels.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = [...action.payload];
      })
      .addCase(createLabel.pending, state => {
        state.status = 'loading';
      })
      .addCase(createLabel.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = [...state.value, action.payload];
      })
      .addCase(deleteLabel.pending, state => {
        state.status = 'loading';
      })
      .addCase(deleteLabel.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = [...state.value.filter(e => e._id !== action.payload._id)];
      })
      .addCase(updateLabel.pending, state => {
        state.status = 'loading';
      })
      .addCase(updateLabel.fulfilled, (state, action) => {
        state.status = 'idle';
        const newState = state.value.map(label =>
          action.payload._id === label._id ? { ...label, ...action.payload } : label,
        );
        state.value = newState;
      });
  },
});

export const selectLabels = state => state?.labels?.value;
// we can have our own functions here

export default labelsSlice.reducer;
