import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import AddMarathonPopup from '../AddMarathon/AddMarathonPopup';

const MarathonRowWrapper = styled.div`
  position: absolute;
  top: ${props => props.top};
  left: ${props => props.left};
  width: ${props => props.width};
  height: 40px;
  z-index: 10;
  cursor: pointer;
`;

const RowWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  margin-top: ${props => props.margin};
`;

const BgWrapper = styled.div`
  position: absolute;
  display: flex;
  z-index: 90;
  width: 100%;
  height: 100%;
  background-color: ${props => props.bgColor};
  border-radius: 5px;
`;

const Completed = styled.div`
  background-color: ${props => props.bgColor};
  border-radius: 5px;
  width: ${props => props.progress};
  height: 100%;
`;

const Content = styled.div`
  position: relative;
  display: flex;
  padding: 5px 5px;
  z-index: 100;
  height: 100%;
  width: 100%;
  overflow: hidden;
  min-width: 0;
`;

const TitleCard = styled.span`
  background-color: #ffffff;

  display: inline-block;
  text-overflow: ellipsis;
  max-height: 40px;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;

  padding: 5px 10px;
  border-radius: 5px;
  margin: 0px 2.5px;
`;

const TitleCardDashboard = styled.span`
  background-color: #ffffff;
  display: flex;

  padding: 5px 10px;
  border-radius: 5px;
  margin: 5px;
`;

const ProgressCard = styled.span`
  background-color: rgba(239, 239, 239, 0.7); /* #efefef */

  display: inline-block;
  text-overflow: ellipsis;
  max-height: 40px;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;

  padding: 5px 10px;
  border-radius: 5px;
  margin: 0px 2.5px;
`;

function MarathonRow({ position, progress, marathon, label }) {
  const { top, left, width } = position;
  const { _id, title, goalProgress, goalTotal, goalTask, goalVerb } = marathon;

  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <>
      <MarathonRowWrapper top={top} left={left} width={width} key={_id} onClick={handleOpen}>
        <RowWrapper>
          <BgWrapper bgColor={label?.shade || '#E3E3E3'}>
            <Completed progress={progress} bgColor={label?.color || '#656F6E'} />
          </BgWrapper>
          <Content>
            <TitleCard>{title}</TitleCard>
            <ProgressCard>
              {goalProgress} / {goalTotal} {goalTask} {goalVerb}
            </ProgressCard>
          </Content>
        </RowWrapper>
      </MarathonRowWrapper>
      <AddMarathonPopup isOpen={isOpen} onClose={handleClose} mode="edit" data={marathon} />
    </>
  );
}

export default MarathonRow;

export const DashboardMarathonRow = ({ progress, marathon, label }) => {
  const { title } = marathon;

  return (
    <RowWrapper margin="5px">
      <BgWrapper bgColor={label?.shade || '#E3E3E3'}>
        <Completed progress={progress} bgColor={label?.color || '#656F6E'} />
      </BgWrapper>
      <Content>
        <TitleCardDashboard>{title}</TitleCardDashboard>
      </Content>
    </RowWrapper>
  );
};
