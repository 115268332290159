import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectLabels } from '../../../features/labels/labelsSlice';
import { selectMarathons } from '../../../features/marathon/marathonSlice';
import { selectMonth } from '../../../features/month/monthSlice';
import useWindowDimensions from '../../../Hooks/useWindowDimensions';
import MarathonColumn from '../Column/MarathonColumn';
import {
  ColumnsWrapper,
  DateBtn,
  DateWrapper,
  MarathonsWrapper,
  PositionWrapper,
} from '../Column/styled/Column.styled';
import MarathonRow from '../Marathon/MarathonRow';

function ViewMarathons() {
  const dimensions = useWindowDimensions();
  const marathonsData = useSelector(selectMarathons);
  const month = useSelector(selectMonth);
  const labels = useSelector(selectLabels);

  const [monthData, setMonthData] = useState([]);
  const [marathons, setMarathons] = useState([]);
  const [columnHeight, setColumnHeight] = useState();

  useEffect(() => {
    const m = moment(month);
    const startDate = m.clone().startOf('month').startOf('week');
    const endDate = m.clone().endOf('month');

    const day = startDate.clone().subtract(1, 'day');
    let weekDays = {};
    let weekIdx = 0;
    while (day.isBefore(endDate, 'day')) {
      weekDays = {
        ...weekDays,
        [weekIdx]: Array(7)
          .fill({})
          .map(() => {
            day.add(1, 'day');
            return {
              day: day.clone().format('DD'),
              year: day.clone().year(),
              month: day.clone().month() + 1,
              date: day.clone().toISOString(),
            };
          }),
      };
      weekIdx++;
    }
    const days = Object.values(weekDays).flat();
    setMonthData(days);
  }, [month]);

  useEffect(() => {
    const vhPx = (dimensions.height * 62) / 100;
    const elemH = marathonsData.length * 50;

    if (vhPx > elemH) {
      setColumnHeight(vhPx);
    } else {
      setColumnHeight(elemH);
    }
  }, [dimensions.height, marathonsData]);

  useEffect(() => {
    const m = marathonsData.map((marathon, index) => {
      const { _id, dateRangeFrom, dateRangeTo, goalProgress, goalTotal, labelId } = marathon;
      const startDate = moment(dateRangeFrom);
      const endDate = moment(dateRangeTo);

      const startIdx = monthData.findIndex(m => moment(m.date).isSame(startDate, 'date'));
      let endIdx = monthData.findIndex(m => moment(m.date).isSame(endDate, 'date'));

      if (endIdx === -1) {
        endIdx = monthData.length - 1;
      }
      const label = labels.find(label => label._id === labelId);

      return {
        _id,
        data: marathon,
        top: `${index * 50}px`,
        left: `${startIdx * 54.3}px`,
        width: `${(endIdx - startIdx + 1) * 54.3}px`,
        progress: `${(goalProgress / goalTotal) * 100}%`,
        label,
      };
    });

    setMarathons(m);
  }, [marathonsData, monthData, labels]);

  return (
    <>
      <MarathonsWrapper>
        <PositionWrapper>
          <ColumnsWrapper>
            {monthData.map((date, index) => (
              <MarathonColumn date={date} key={index} length={monthData.length} height={columnHeight} />
            ))}
          </ColumnsWrapper>
          {marathons.map(marathon => (
            <MarathonRow
              key={marathon._id}
              position={{ top: marathon.top, left: marathon.left, width: marathon.width }}
              progress={marathon.progress}
              marathon={marathon.data}
              label={marathon.label}
            />
          ))}
          {/* <div style={{ position: 'absolute', top: 0, left: 10 }}></div> */}
        </PositionWrapper>
        <DateWrapper>
          {monthData.map((date, index) => (
            <DateBtn
              key={index}
              isToday={moment(date.date).isSame(new Date(), 'date')}
              isMonth={moment(date.date).isSame(new Date(month), 'month')}
              width={99 / monthData.length}
            >
              {date.day}
            </DateBtn>
          ))}
        </DateWrapper>
      </MarathonsWrapper>
    </>
  );
}

export default ViewMarathons;
