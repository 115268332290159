import styled from 'styled-components';
import { colors } from './colors';

export const StyledSubmitButton = styled.button`
  width: 100%;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  user-select: none;
  box-shadow: 0 2 5 ${colors.mainColor};
  background-color: ${colors.formElementsColor};
  color: ${colors.boxBg};

  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1), inset 0px 0px 2px rgba(0, 0, 0, 0.1);

  &:disabled {
    background-color: ${colors.formElementsColor};
    cursor: not-allowed;
  }

  span {
    font-weight: bold;
    font-size: 16px;
  }
`;

const SubmitButton = ({ action, caption = 'Log In', disabled }) => {
  const handleSubmit = e => {
    e.preventDefault();
    action();
  };
  return (
    <StyledSubmitButton className="gap-custom-1" onClick={handleSubmit} disabled={disabled}>
      <span>{caption}</span>
    </StyledSubmitButton>
  );
};

export default SubmitButton;
