import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ActionButton } from '../../Components/Button/Button';
import { DashboardContent } from '../../Components/DashboardNavbar/styled/DashboardNavbar.styled';
import { ToPortalButton } from '../../Components/MarathonComponents/EmptyMarathon/styled/EmptyMarathon.styled';
import { DashboardMarathonRow } from '../../Components/MarathonComponents/Marathon/MarathonRow';
import { NewNavbar } from '../../Components/Navbar/NewNavbar';
import { DashboardTask } from '../../Components/Task/Task';
import { Widget } from '../../Components/Widgets';
import { EmptyPlaceholder } from '../../Components/Widgets/Empty';
import {
  ChildrenContainer,
  HowToWrapper,
  MarathonsWrapper,
  NavButtonContainer,
  TasksWrapper,
} from '../../Components/Widgets/styled/widget.styled';
import useAuth from '../../features/auth/hooks/useAuth';
import { selectLabels } from '../../features/labels/labelsSlice';
import { getMarathonsForDay, selectMarathons } from '../../features/marathon/marathonSlice';
import { getTasksByDate, selectTasks } from '../../features/tasks/tasksSlice';

const Dashboard = () => {
  const { user, isError } = useSelector(state => state.auth);
  const { isAuthenticated } = useAuth();
  const dispatch = useDispatch();
  const tasks = useSelector(selectTasks);
  const marathonsData = useSelector(selectMarathons);
  const labels = useSelector(selectLabels);

  const [marathons, setMarathons] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    if (user === null || user === undefined || !isAuthenticated || isError) {
      navigate('/login');
    }
  }, [isAuthenticated, navigate, user]);

  useEffect(() => {
    dispatch(getTasksByDate({ date: new Date().toISOString() }));
    dispatch(getMarathonsForDay({ date: new Date().toISOString() }));
  }, []);

  useEffect(() => {
    const m = marathonsData.map((marathon, index) => {
      const { _id, goalProgress, goalTotal, labelId } = marathon;

      const label = labels.find(label => label._id === labelId);

      return {
        _id,
        data: marathon,
        progress: `${(goalProgress / goalTotal) * 100}%`,
        label,
      };
    });

    setMarathons(m);
  }, [marathonsData, labels]);

  const handleExternal = url => {
    window.open(url);
  };

  return (
    <div>
      {/* <DashboardNavbar /> */}
      <NewNavbar />
      <DashboardContent className="body">
        <Widget title="To do today">
          <ChildrenContainer>
            {tasks.length > 0 ? (
              <TasksWrapper>
                {tasks.map(task => (
                  <DashboardTask item={task} key={task._id} />
                ))}
              </TasksWrapper>
            ) : (
              <EmptyPlaceholder description="You do not have any tasks left to do today." />
            )}
            <NavButtonContainer>
              <ToPortalButton onClick={() => navigate('/shuffle')}>
                <span className="text">VIEW SHUFFLE</span>
              </ToPortalButton>
            </NavButtonContainer>
          </ChildrenContainer>
        </Widget>
        <Widget title={`${marathonsData.length}`} subtitle="Marathons in progress">
          <ChildrenContainer>
            {marathons.length > 0 ? (
              <MarathonsWrapper>
                {marathons.map(marathon => (
                  <DashboardMarathonRow
                    key={marathon._id}
                    progress={marathon.progress}
                    marathon={marathon.data}
                    label={marathon.label}
                  />
                ))}
              </MarathonsWrapper>
            ) : (
              <EmptyPlaceholder description="You do not have any Marathons left to do today." />
            )}
            <NavButtonContainer>
              <ToPortalButton>
                <span className="text" onClick={() => navigate('/marathons')}>
                  VIEW MARATHONS
                </span>
              </ToPortalButton>
            </NavButtonContainer>
          </ChildrenContainer>
        </Widget>
        <Widget title="How-to's">
          <ChildrenContainer style={{ marginBottom: '30px' }}>
            <HowToWrapper>
              <ActionButton
                className="btn d-flex"
                center={true}
                style={{ marginBottom: '20px' }}
                onClick={() => handleExternal('https://www.bilalpervez.com/mastershop-help/setting-the-right-goals/')}
              >
                <span className="btn-text btn-center">Setting goals</span>
              </ActionButton>
              <ActionButton
                className="btn d-flex"
                center={true}
                style={{ marginBottom: '20px' }}
                onClick={() => handleExternal('https://www.bilalpervez.com/mastershop-help/planning-your-tasks')}
              >
                <span className="btn-text btn-center">Planning tasks</span>
              </ActionButton>
              <ActionButton
                className="btn d-flex"
                center={true}
                style={{ marginBottom: '20px' }}
                onClick={() => handleExternal('https://www.bilalpervez.com/mastershop-help/choosing-your-labels')}
              >
                <span className="btn-text btn-center">Creating labels</span>
              </ActionButton>
            </HowToWrapper>
          </ChildrenContainer>
        </Widget>
      </DashboardContent>
    </div>
  );
};

Dashboard.propTypes = {};

export default Dashboard;
