import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

export const StyledInput = styled.input`
  border: none;
  font-size: ${props => (props.varient === 'big' ? '18px' : '14px')};
  background-color: transparent;
  outline: none;
  width: 100%;
`;

export const InputWrapper = styled.div`
  border: ${props => (props.error ? '1px solid #DF3131' : '1px solid #e3e8ee')};
  border-radius: 5px;
  padding: ${props => (props.varient === 'big' ? ' 10px 20px' : '5px 10px')};
  width: 100%;
  background-color: #ffffff;
  min-width: 45px;

  :focus-within {
    border: 1px solid #29c9de;
  }
`;

export const HintText = styled.span`
  margin-top: 7px;
  font-size: 14px;
  color: #909090;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

function Input({ varient, hint, leadingIcon, classNameWrapper, error, ...props }) {
  return (
    <>
      <Wrapper className={classNameWrapper}>
        <InputWrapper varient={varient} error={error}>
          <StyledInput varient={varient} {...props}></StyledInput>
        </InputWrapper>
        {hint && <HintText>{hint}</HintText>}
      </Wrapper>
    </>
  );
}

Input.propTypes = {
  varient: PropTypes.oneOf(['big', 'small']),
  hint: PropTypes.string,
};

export default Input;
