import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Stepper } from '../../Components/Stepper/Stepper';
import { finishOnboarding } from '../../features/auth/services/auth.service';
import { StepComplete } from './pages/StepComplete';
import { StepGoal } from './pages/StepGoal';
import { StepLabel } from './pages/StepLabel';
import { StepTask } from './pages/StepTask';
import { StepWelcome } from './pages/StepWelcome';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  background-color: #fcfcfc;
  padding: 30px;
  ${props => (props.currentPage !== 3 && `height: 100vh`)};
  @media screen and (min-height: 759px) {
    height: 100vh;
  }
`;

const StepperWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 30%;
  @media screen and (max-width: 960px) {
    width: 50%;
  }
  @media screen and (max-width: 640px) {
    width: 100%;
  }
  @media screen and (max-width: 376px) {
    width: 100%;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const STEPS = [{ index: 0 }, { index: 1 }, { index: 2 }, { index: 3 }, { index: 4 }];

export const Onboarding = () => {
  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [onboardingData, setOnboardingData] = useState({
    welcome: { status: 'not-done' },
    label: { status: 'not-done', count: 0 },
    task: { status: 'not-done', count: 0 },
    goal: { status: 'not-done', count: 0 },
  });

  const handleStepperAction = nextPage => {
    setPage(nextPage);
  };

  const handleNext = (data, type) => {
    if (data && type) {
      setOnboardingData({
        ...onboardingData,
        [type]: {
          ...onboardingData.type,
          ...data,
        },
      });
    }
    if (page < 5) {
      setPage(page + 1);
    }
  };

  const handleFinish = () => {
    finishOnboarding()
      .then(res => {
        navigate('/');
      })
      .catch(err => console.log(err));
  };

  const CurrentStep = [
    <StepWelcome onNext={handleNext} onSkip={handleFinish} />,
    <StepLabel onNext={handleNext} />,
    <StepTask onNext={handleNext} />,
    <StepGoal onNext={handleNext} />,
    <StepComplete onboardingData={onboardingData} onFinish={handleFinish} />,
  ][page];

  return (
    <Container currentPage={page}>
      <StepperWrapper>
        <ContentWrapper>
          <Stepper stepperAction={handleStepperAction} steps={STEPS} page={page} />
          {CurrentStep}
        </ContentWrapper>
      </StepperWrapper>
    </Container>
  );
};
