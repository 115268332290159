import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Input } from '../../../Components/AddLabel/OnboardingLabel';
import { HeadlessSelector } from '../../../Components/LabelsSelectDropdown/HeadlessSelector';
import { getLabels } from '../../../features/labels/labelsSlice';
import { createTask } from '../../../features/tasks/tasksSlice';
import { ActionButton, Title } from './Onboarding.styled';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const TaskWrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

const LabelWrapper = styled.div`
  width: 70%;
`;

const LabelText = styled.h4`
  margin-top: 20px;
  color: #5a5a5a;
  font-size: 15px;
  font-weight: 700;
`;

export const StepTask = ({ onNext }) => {
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.auth);

  const [value, setValue] = useState('');
  const [selectedLabel, setSelectedLabel] = useState(null);

  useEffect(() => {
    dispatch(getLabels(user._id));
  }, [dispatch, user._id]);

  const handleInput = event => {
    event.preventDefault();
    setValue(event.target.value);
  };

  const handleSubmit = () => {
    dispatch(
      createTask({
        title: value,
        date: new Date(),
        index: 0,
        labelId: selectedLabel,
      }),
    );
  };

  const handleNext = () => {
    handleSubmit();
    onNext({ status: 'done', count: 1 }, 'task');
  };

  return (
    <Container>
      <Title>
        Enter a task you plan to do today, <span className="underline">and attach a label to it</span>
      </Title>
      <TaskWrapper>
        <Input value={value} onChange={handleInput} placeholder={`Task for today`} />
        <LabelText>Pick a label</LabelText>
        <LabelWrapper>
          <HeadlessSelector setSelectedLabel={setSelectedLabel} selectedLabel={selectedLabel} width="100%" />
        </LabelWrapper>
      </TaskWrapper>
      <ActionButton disabled={value.trim().length === 0} style={{ textTransform: 'uppercase' }} onClick={handleNext}>
        NEXT
      </ActionButton>
    </Container>
  );
};
