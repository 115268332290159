/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import styled from 'styled-components';
import Navbar from '../../Components/Navbar/Navbar';
import { NewNavbar } from '../../Components/Navbar/NewNavbar';
import './home.css';

const BgContainer = styled.div`
  background-color: #fcfcfc;
  text-align: center;
  width: 100vw;
  height: 100vh;
  overflow: auto;
`;

function Home({ type, children }) {
  return (
    <>
      <BgContainer>
        {/* <Navbar type={type} /> */}
        <NewNavbar type={type}/>
        {children}
      </BgContainer>
    </>
  );
}

export default Home;
