export function wordify(n) {
  var word = [],
    numbers = {
      1: 'One',
      2: 'Two',
      3: 'Three',
      4: 'Four',
      5: 'Five',
      6: 'Six',
      7: 'Seven',
      8: 'Eight',
      9: 'Nine',
      10: 'Ten',
      11: 'Eleven',
      12: 'Twelve',
      t3: 'Thir',
      t5: 'Fif',
      t8: 'Eigh',
      20: 'Twenty',
    },
    hundreds = 0 | ((n % 1000) / 100),
    tens = 0 | ((n % 100) / 10),
    ones = n % 10,
    part;

  if (n === 0) return 'Zero';
  if (hundreds) word.push(numbers[hundreds] + ' Hundred');

  if (tens === 0) {
    word.push(numbers[ones]);
  } else if (tens === 1) {
    word.push(numbers['1' + ones] || (numbers['t' + ones] || numbers[ones]) + 'teen');
  } else {
    part = numbers[tens + '0'] || (numbers['t' + tens] || numbers[tens]) + 'ty';
    word.push(numbers[ones] ? part + '-' + numbers[ones] : part);
  }
  return word.join(' ');
}
