import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  createMarathonAPI,
  deleteMarathonAPI,
  getAllMarathonsAPI,
  getMarathonByIdAPI,
  getMarathonsByMonthAPI,
  getMarathonsForDayApi,
  updateMarathonAPI,
  updateMarathonProgressAPI,
} from './marathonAPI';

const initialState = {
  value: [],
  status: 'idle',
};

export const createMarathon = createAsyncThunk('marathon/create', async marathon => {
  const data = await createMarathonAPI(marathon);
  return data;
});

export const getMarathonsByMonth = createAsyncThunk('marathon/fetchAllByMonth', async ({ date }) => {
  const data = await getMarathonsByMonthAPI(date);
  return data;
});

export const getMarathonsForDay = createAsyncThunk('marathon/fetchAllForDay', async ({ date }) => {
  const data = await getMarathonsForDayApi(date);
  return data;
});

export const getAllMarathons = createAsyncThunk('marathon/fetchAll', async () => {
  const data = await getAllMarathonsAPI();
  return data;
});

export const getMarathonById = createAsyncThunk('marathon/fetchOne', async id => {
  const data = await getMarathonByIdAPI(id);
  return data;
});

export const updateMarathon = createAsyncThunk('marathon/update', async ({ id, payload }) => {
  const data = await updateMarathonAPI(id, payload);
  return data;
});

export const updateMarathonProgress = createAsyncThunk('marathon/updateProgress', async ({ id, payload }) => {
  const data = await updateMarathonProgressAPI(id, payload);
  return data;
});

export const deleteMarathon = createAsyncThunk('marathon/delete', async id => {
  const data = await deleteMarathonAPI(id);
  return data;
});

export const marathonSlice = createSlice({
  name: 'marathon',
  initialState,
  extraReducers: builder => {
    builder
      .addCase(createMarathon.pending, state => {
        state.status = 'loading';
      })
      .addCase(createMarathon.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = [...state.value, action.payload];
      })
      .addCase(getAllMarathons.pending, state => {
        state.status = 'loading';
      })
      .addCase(getAllMarathons.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = action.payload;
      })
      .addCase(getMarathonsByMonth.pending, state => {
        state.status = 'loading';
        state.value = [];
      })
      .addCase(getMarathonsByMonth.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = action.payload;
      })
      .addCase(getMarathonsByMonth.rejected, state => {
        state.status = 'idle';
        state.value = [];
      })
      .addCase(getMarathonById.pending, state => {
        state.status = 'loading';
      })
      .addCase(getMarathonById.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = [...state.value, action.payload];
      })
      .addCase(updateMarathon.pending, state => {
        state.status = 'loading';
      })
      .addCase(updateMarathon.fulfilled, (state, action) => {
        state.status = 'idle';
        const newState = state.value.map(marathon =>
          action.payload._id === marathon._id ? { ...marathon, ...action.payload } : marathon,
        );
        state.value = newState;
      })
      .addCase(updateMarathonProgress.pending, state => {
        state.status = 'loading';
      })
      .addCase(updateMarathonProgress.fulfilled, (state, action) => {
        state.status = 'idle';
        const newState = state.value.map(marathon =>
          action.payload._id === marathon._id ? { ...marathon, goalProgress: action.payload.goalProgress } : marathon,
        );
        state.value = newState;
      })
      .addCase(deleteMarathon.pending, state => {
        state.status = 'loading';
      })
      .addCase(deleteMarathon.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = [...state.value.filter(m => m._id !== action.payload._id)];
      })
      .addCase(getMarathonsForDay.pending, state => {
        state.status = 'loading';
        state.value = [];
      })
      .addCase(getMarathonsForDay.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = action.payload;
      })
      .addCase(getMarathonsForDay.rejected, state => {
        state.status = 'idle';
        state.value = [];
      });
  },
});

export const selectMarathons = state => state.marathon.value;

export default marathonSlice.reducer;
