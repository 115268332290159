import { ObjectID } from 'bson';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { deleteTask, updateBulk } from '../../features/tasks/tasksSlice';
import { ReactComponent as PlusSVG } from '../../svg/plus.svg';
import { Button } from '../Button/Button';
import { Task } from '../Task/Task';

const DroppableArea = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  min-height: 40px;
  border-radius: 7px;
  border: ${props => (props.isElem ? 'none' : '2px dashed #d4d4d4')};
`;

const WeekHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 6px;
  align-items: center;

  .text {
    font-weight: bold;
  }
`;

const Column = styled.div`
  /* margin-left: 5px;
  margin-right: 5px; */
`;

const TodayDate = styled.span`
  color: #626c6d;
`;

const HoverButton = styled(Button)`
  color: #000000;
  :hover {
    color: #000000;
  }
`;

export const Tasks = ({ element, day, idx, prefix, topRef, setTotalLen, totalLen }) => {
  const id = `${prefix}-${day}`;
  const date = moment(new Date(element.date));

  const [tasks, setTasks] = useState(element.data);

  const isToday = date.isSame(new Date(), 'date');
  const todayRef = useRef();

  const dispatch = useDispatch();

  useEffect(() => {
    function getTasks() {
      setTasks(element.data);
    }
    getTasks();
  }, [element.data]);

  useEffect(() => {
    let refClone;
    if (isToday && topRef) {
      todayRef.current.scrollIntoView();
      refClone = topRef;
    }
    return () => {
      if (isToday && topRef) {
        refClone?.current?.scrollIntoView();
      }
    };
  }, [isToday, topRef, totalLen]);

  const escAddNewTask = useCallback(
    id => {
      setTasks([...tasks.filter(item => item._id !== id)]);
      setTotalLen(prev => prev - 1);
    },
    [tasks],
  );

  const onNewTask = event => {
    setTasks([
      ...tasks,
      {
        _id: new ObjectID().toString(),
        title: '',
        index: tasks.length,
        date: date.toISOString(),
        month: date.month(),
        year: date.year(),
        isEditable: true,
        labelId: null,
      },
    ]);
    setTotalLen(prev => prev + 1);
  };

  const deleteTaskCb = useCallback(
    id => {
      const updatedTasks = tasks
        .filter(t => t._id !== id)
        .map((t, idx) => {
          return { ...t, index: idx };
        });
      dispatch(deleteTask(id));
      dispatch(updateBulk({ tasks: updatedTasks }));
    },
    [dispatch, tasks],
  );

  // console.log(tasks);

  return (
    <>
      <Column ref={todayRef} key={idx} id={`${isToday ? 'today' : 'day'}`}>
        <WeekHeader>
          <span className="text">
            {date.format('dddd Do')}
            {isToday && (
              <span>
                {' - '}
                <TodayDate>Today</TodayDate>
              </span>
            )}
          </span>
          <HoverButton className="btn" onClick={onNewTask}>
            <PlusSVG />
          </HoverButton>
        </WeekHeader>
        <Droppable droppableId={id} type="TASK" ignoreContainerClipping={Boolean(true)}>
          {provided => (
            <DroppableArea id="tasks" isElem={tasks.length > 0} {...provided.droppableProps} ref={provided.innerRef}>
              {tasks.map((item, index) => (
                <Task
                  key={item._id}
                  item={item}
                  index={index}
                  escCallback={escAddNewTask}
                  onDeleteTask={deleteTaskCb}
                />
              ))}
              {provided.placeholder}
            </DroppableArea>
          )}
        </Droppable>
      </Column>
    </>
  );
};
