const apiEndpoint = {
  login: '/api/login',
  register: '/api/register',
  getProfile: '/api/profile',
  resetPassword: '/api/reset-password',
  forgotPassword: '/api/forgot-password',
  updatePassword: '/api/update-password',
  finishOnboarding: '/api/profile/onboarding',
};

export default apiEndpoint;
