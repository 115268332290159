import styled from 'styled-components';
import GlitterSVG from '../../../svg/glitter.svg';

export const Container = styled.div`
  background-color: #fefefe;
  display: flex;
  width: 90%;
  border: 1px solid #e8e8e8;
  border-radius: 10px;
  margin: 20px;
  flex-direction: column;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: start;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 10px;
`;

export const Title = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  font-size: 20px;
`;

export const SecondryTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  font-size: 16px;
`;

export const EmptyContainer = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url('${GlitterSVG}');

  .description {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
    font-size: 16px;
    text-align: center;
  }
`;

export const ChildrenContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;
`;

export const TasksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 60%;
  margin: auto;
`;

export const MarathonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80%;
  margin: auto;
`;

export const HowToWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80%;
  margin: auto;
`;

export const NavButtonContainer = styled.div`
  display: flex;
  margin-top: 5px;
  margin-bottom: 10px;
  width: 100%;
  justify-content: center;
`;
