import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useEventListener } from '../../Hooks/useEventListener';
import { ReactComponent as CloseSVG } from '../../svg/close.svg';
import { ReactComponent as DeleteSVG } from '../../svg/delete.svg';
import { ReactComponent as EditTaskLabel } from '../../svg/edit-task-label.svg';
import { Button } from '../Button/Button';
import { HeadlessSelector } from '../LabelsSelectDropdown/HeadlessSelector';
import { DialogContent, TopBar } from '../StyledModal/StyledModal';
import { StyledInput } from '../Task/styled/Input';

const TaskSelectWrapper = styled.div`
  display: flex;
  margin-top: 10px;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
`;

const InputWrapper = styled.div`
  display: flex;
  margin-left: 40px;
  margin-right: 10px;
  margin-top: 13px;

  @media (min-width: 320px) {
    margin-right: 10px;
  }

  @media (min-width: 641px) {
    margin-right: 10%;
  }
`;

export const TaskEditModal = ({
  item,
  onEditCallback,
  onDeleteCallback,
  onCloseCallback,
  setSelectedLabel,
  selectedLabel,
  setTitle,
}) => {
  const [content, setContent] = useState(item.title);

  useEffect(() => {
    setContent(item.title);
  }, [item.title]);

  const onChangeTitle = e => {
    setContent(e.target.value);
    setTitle(e.target.value);
  };

  const handler = ({ key }) => {
    if (['13', 'Enter'].includes(String(key))) {
      onEditCallback({
        ...item,
        title: content,
        labelId: selectedLabel,
      });
    }
  };

  useEventListener('keydown', handler);

  return (
    <>
      <TopBar>
        <Button className="btn btn-outline icon">
          <DeleteSVG className="svg--delete" onClick={() => onDeleteCallback()} />
        </Button>
        <Button className="btn btn-outline icon">
          <CloseSVG
            className="svg--delete"
            onClick={() =>
              onCloseCallback({
                ...item,
                title: content,
                labelId: selectedLabel,
              })
            }
          />
        </Button>
      </TopBar>
      <DialogContent>
        <InputWrapper>
          <StyledInput
            style={{ fontWeight: 600, fontSize: '15px' }}
            isBorder
            type="text"
            className="form-control"
            placeholder="Write a Concise Title"
            value={content}
            onChange={onChangeTitle}
            autoFocus
          />
        </InputWrapper>
        <TaskSelectWrapper>
          <EditTaskLabel style={{ width: '20px', height: '20px', marginRight: '10px' }} />
          <HeadlessSelector setSelectedLabel={setSelectedLabel} selectedLabel={selectedLabel} width="60%" />
        </TaskSelectWrapper>
      </DialogContent>
    </>
  );
};
