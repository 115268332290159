import styled from 'styled-components';
import { ReactComponent as CheckBoxSVG } from '../../svg/checkbox.svg';

const CheckBoxCheckMark = styled(CheckBoxSVG)`
  width: ${props => (props.varient === 'small' ? '10px' : '15px')};
  height: ${props => (props.varient === 'small' ? '10px' : '15px')};
  margin: ${props => (props.varient === 'small' ? '1px' : '5px')};
  opacity: 0;
  transform: ${props => (props.varient === 'small' ? 'translateY(-2px)' : 'translateY(-5px)')};
  transition: all 0.1s ease-out;

  &.active {
    opacity: 1;
    transform: none;
  }
`;

const CheckBox = styled.div`
  width: ${props => (props.varient === 'small' ? '18px' : '31px')};
  height: ${props => (props.varient === 'small' ? '18px' : '31px')};
  border: 3px solid #eee;
  border-radius: 5px;

  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
`;

function Checkbox({ action, isActive, varient }) {
  const handleClick = e => {
    e.preventDefault();
    action();
  };
  return (
    <CheckBox onClick={handleClick} varient={varient}>
      <CheckBoxCheckMark className={isActive ? 'active' : ''} varient={varient} />
    </CheckBox>
  );
}

export default Checkbox;
