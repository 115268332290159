import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  LabelBackArrow,
  LabelColorSelectTick,
  LabelCreateNewColorHeader,
  LabelCreateNewColorSelect,
  LabelCreateNewCreateButton,
  LabelCreateNewText,
  LabelCreateNewTextBox,
} from './styled/AddLabel.styled';
import { useDispatch } from 'react-redux';
import { createLabel } from '../../features/labels/labelsSlice';
import useAuth from '../../features/auth/hooks/useAuth';

const AddLabel = ({ setShowAddLabel, showAddLabel }) => {
  const types = [
    { color: '#89E18D', shade: '#DCF9DD' },
    { color: '#E2A161', shade: '#F7D6B7' },
    { color: '#4674B1', shade: '#BDD5F4' },
    { color: '#7BC6B1', shade: '#F4E0E0' },
    { color: '#EFB5B4', shade: '#F4E0E0' },
    { color: '#ECE690', shade: '#E3E3E3' },
  ];

  const [selectedColor, setSelectedColor] = useState({});
  const [labelName, setLabelName] = useState('');

  const auth = useAuth();
  const dispatch = useDispatch();

  const onClickLabelColor = (e, color) => {
    e.preventDefault();
    if (selectedColor.color !== color) {
      setSelectedColor(color);
    } else {
      setSelectedColor({});
    }
    e.stopPropagation();
  };

  const onClickCreateLabel = () => {
    if (labelName && selectedColor) {
      const label = {
        userId: auth.getUser()._id,
        title: labelName,
        color: selectedColor.color,
        shade: selectedColor.shade,
      };
      dispatch(createLabel(label));
    } else {
      // console.log('enter label name and Select Color');
    }
  };

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'baseline', marginBottom: '9px', marginTop: '12px' }}>
        <LabelBackArrow onClick={() => setShowAddLabel(false)} />
        <LabelCreateNewText>Create new label</LabelCreateNewText>
      </div>
      <LabelCreateNewTextBox
        value={labelName}
        onChange={e => setLabelName(e.target.value)}
        placeholder="Write a label name"
      />
      <LabelCreateNewColorHeader>Colour</LabelCreateNewColorHeader>
      <div style={{ display: 'grid', gap: '10px 10px', gridTemplateColumns: 'auto auto auto' }}>
        {types.map((type, idx) => (
          <>
            <LabelCreateNewColorSelect key={idx} color={type.color} onClick={e => onClickLabelColor(e, type)}>
              {selectedColor.color === type.color && <LabelColorSelectTick />}
            </LabelCreateNewColorSelect>
          </>
        ))}
      </div>
      <LabelCreateNewCreateButton
        onClick={() => {
          setShowAddLabel(!showAddLabel);
          onClickCreateLabel();
        }}
      >
        Create
      </LabelCreateNewCreateButton>
    </>
  );
};

AddLabel.propTypes = {};

export default AddLabel;
