import React from 'react';
import styled from 'styled-components';
import { MarathonColumnWrapper } from './styled/Column.styled';

const Column = styled.div`
  height: 100%;
  border-right: 1px solid #dadfe0;
  width: ${props => `${props.width}vw`};
  min-width: 54px;
`;

function MarathonColumn({ date, length, height }) {
  const width = 99 / length;
  return (
    <>
      <MarathonColumnWrapper height={height}>
        <Column width={width} />
      </MarathonColumnWrapper>
    </>
  );
}

export default MarathonColumn;
