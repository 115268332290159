import styled from 'styled-components';
import { ReactComponent as LogoSVG } from '../../svg/logo.svg';
import { ReactComponent as UserAvatarSVG } from '../../svg/testomonial-user-avatar.svg';
import { ReactComponent as RatingStarsSVG } from '../../svg/testomonial-rating-stars.svg';
import { ReactComponent as RegiterTextSVG } from '../../svg/simple-and-effective-task-management.svg';
import RegisterPageBackground from '../../svg/register-page-background.jpg';
import { colors } from './colors';
import { device } from './media';

const ColorWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: grid;
  place-content: center;
  background-color: ${colors.mainBg};

  @media screen and ${device.mobileM} {
    display: flex;
    justify-content: center;
    align-items: center;

    .title {
      font-size: 18px;
    }
  }
`;

const ContentBoxWrapper = styled.div`
  display: flex;
  width: 100vw;
  height: 100%;
  gap: 0px;

  @media screen and (max-width: 640px) {
    flex-direction: column-reverse;
    height: 100%;
    /* width: 100vh; */
  }
`;

const ContainerFB = styled.div`
  display: flex;
  width: 40%;
  min-height: 100vh;
  align-items: center;
  background-color: #fcfcfc;

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 640px) {
    width: 100%;
    height: 100%;
    min-height: 100%;
  }
  @media screen and (max-width: 376px) {
    width: 100%;
    height: 100%;
    min-height: 100%;
  }
`;
const ContainerTS = styled.div`
  display: flex;
  width: 60%;

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 640px) {
    width: 100%;
    height: 100%;
  }
  @media screen and (max-width: 376px) {
    width: 100%;
    height: 100%;
  }
`;

const FormBoxWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
  padding: 0rem 1.5rem 0rem 1.5rem;
  height: fit-content;
  svg {
    width: 506px;
    height: 31px;
  }
  @media screen and (max-width: 960px) {
  }

  @media screen and (max-width: 640px) {
    margin-top: 43px;
    width: 100vw;
    svg {
      width: 334px;
      height: 31px;
    }
  }
  @media screen and (max-width: 376px) {
    margin-top: 43px;
    width: 100vw;
    svg {
      width: 334px;
      height: 31px;
    }
  }
`;
const TestomonyBoxWrapper = styled.div`
  background-image: url(${RegisterPageBackground});
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 640px) {
    width: 100%;
    height: fit-content;
  }
  @media screen and (max-width: 376px) {
    width: 100%;
    height: fit-content;
  }
`;

const ContainerTSBOX = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 640px) {
    width: 100vw;
    padding-bottom: 40px;
    padding-top: 40px;
  }
  @media screen and (max-width: 376px) {
    width: 100vw;
    padding-bottom: 40px;
    padding-top: 40px;
  }
`;

const Logo = styled.div`
  @import url('https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap');

  display: flex;
  flex-direction: column;
  height: fit-content;
  justify-content: center;
  svg {
    margin-right: 10px;
    width: 20.76px;
    height: 15.87px;

    path {
      fill: ${colors.formElementsColor};
    }
  }

  @media screen and (max-width: 640px) {
  }
`;
const MastershopTitle = styled.span`
  @import url('https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap');
  color: ${colors.mainColor};

  font-family: 'Noto Sans';
  font-weight: 400;
  font-size: 21.0671px;
  line-height: 21px;
  letter-spacing: -0.055em;
  margin-top: 10px;
  margin-left: 5px;
  font-style: normal;
`;
const UserAvatar = styled.div`
  @import url('https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap');

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 18px;

  svg {
    path {
      fill: white;
    }
  }

  @media screen and (max-width: 640px) {
    svg {
      margin-top: 10px;
    }
  }
`;

const ContentBox = styled.div`
  @import url('https://use.typekit.net/mct2hpb.css');
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* gap: 1rem; */

  .gap-custom-1 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .gap-custom-password-mismatch {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;

    font-family: 'myriad-pro';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    /* identical to box height */

    color: #1a6f7a;
  }

  /* @supports (-webkit-touch-callout: none) and (not (translate: none)) {
    &:not(:last-child) {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }
  } */

  width: 100%;

  h1.form-title {
    font-size: 25px;
    font-weight: normal;
  }
  @media screen and (max-width: 640px) {
    h1.form-title {
      font-size: 21px;
    }
  }

  .task_title {
    font-family: 'myriad-pro';
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;

    margin-bottom: 25px;
    margin-top: 25px;

    color: #505050;
  }
`;
const TestomonyBox = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: auto;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  gap: 1rem;
  width: 531px;
  height: fit-content;

  @media screen and (max-width: 960px) {
    width: 341px;
    height: fit-content;
  }
  @media screen and (max-width: 640px) {
    width: 531px;
    height: fit-content;
  }
  @media screen and (max-width: 640px) {
    width: 283px;
    height: fit-content;
    gap: 0;
  }
`;
const Testomony = styled.span`
  font-family: 'Lora';
  font-style: normal;
  font-weight: 400;
  font-size: 34.8708px;
  line-height: 45px;
  text-align: center;

  color: #ffffff;
  @media screen and (max-width: 640px) {
    line-height: 32px;
    font-size: 25px;
  }

  .bold {
    font-family: 'Lora';
    font-style: normal;
    font-weight: 400;
    font-size: 34.8708px;
    line-height: 45px;
    text-align: center;
    font-weight: 700;

    @media screen and (max-width: 640px) {
      line-height: 32px;
      font-size: 25px;
    }
  }
`;
const UserName = styled.div`
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  font-size: 20.9225px;
  line-height: 25px;
  text-align: center;

  color: #ffffff;
  @media screen and (max-width: 640px) {
    font-size: 20.9225px;
    line-height: 25px;
  }
`;

function AuthWrapper({ caption, children, type }) {
  return (
    <ContentBoxWrapper>
      <ContainerTS>
        <TestomonyBoxWrapper>
          <ContainerTSBOX>
            <UserAvatar>
              <UserAvatarSVG />
              <RatingStarsSVG />
            </UserAvatar>
            <TestomonyBox>
              <Testomony>
                “Before Mastershop, my productivity was a third of what it is today.{' '}
                <span className="bold">This is the most impactful task management tool I've ever used</span>."
              </Testomony>
              <UserName>- Galileo, a very happy member!</UserName>
            </TestomonyBox>
          </ContainerTSBOX>
        </TestomonyBoxWrapper>
      </ContainerTS>
      <ContainerFB>
        <FormBoxWrapper>
          <Logo>
            <LogoSVG />
            <MastershopTitle>Mastershop</MastershopTitle>
          </Logo>
          <ContentBox>
            <h1 className="task_title">{caption}</h1>
            {children}
          </ContentBox>
        </FormBoxWrapper>
      </ContainerFB>
    </ContentBoxWrapper>
  );
}

export default AuthWrapper;
