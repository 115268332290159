import styled from 'styled-components';
import { ReactComponent as CloseAddTaskSVG } from '../../../svg/close-add-task.svg';

export const StyledInput = styled.input`
  /* width: 100% !important; */
  padding-top: 5px;
  padding-bottom: 5px;
  border: ${props => (props.isBorder ? '1px solid #E3E8EE !important' : 'none')};
  text-indent: 10px;
  :focus {
    outline: none !important;
    box-shadow: none !important;
  }
`;
export const AddTaskButton = styled.button`
  width: 74px;
  height: 27px;
  white-space: nowrap;

  background: #29c9de;
  box-shadow: 0px 1.36255px 2.7251px rgba(0, 0, 0, 0.1), inset 0px 0px 1.36255px rgba(0, 0, 0, 0.1);
  border-radius: 2.7251px;
  border: none;
  white-space: nowrap;
  margin-top: 9px;
  margin-left: 10px;

  color: #ffffff;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
`;

export const ClodeAddTaskButton = styled(CloseAddTaskSVG)`
  margin-left: 14px;
  margin-top: 16px;
  cursor: pointer;
`;
