import styled from 'styled-components';
import { ReactComponent as LabelBackArrowSVG } from '../../../svg/label-back-arrow.svg';
import { ReactComponent as LabelColorSelectTickSVG } from '../../../svg/label-color-select-tick.svg';

export const EditLabelBackArrow = styled(LabelBackArrowSVG)`
  cursor: pointer;
  margin-right: 12px;
`;

export const EditLabelCreateNewText = styled.div`
  width: 146px;
  height: 20px;

  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;

  color: #454545;
`;
export const EditLabelCreateNewTextBox = styled.input`
  width: 347px;
  height: 34px;
  text-indent: 10px;
  background: #ffffff;
  border: 1px solid #e3e8ee;
  box-sizing: border-box;
  border-radius: 5px;

  margin-top: 13px;
  margin-bottom: 19px;
`;
export const EditLabelCreateNewColorHeader = styled.div`
  width: 301.09px;
  height: 16px;

  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.1em;

  margin-bottom: 17px;
  color: #454545;
`;
export const EditLabelCreateNewColorSelect = styled.div`
  width: 109px;
  height: 60px;
  background: ${props => props.color};
  border-radius: 9.19458px;
  cursor: pointer;
`;

export const EditLabelCreateNewCreateButton = styled.button`
  width: 347px;
  height: 40px;

  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1), inset 0px 0px 2px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  border: 1px solid #29c9de;
  background: #ffffff;
  margin-top: 30px;
  margin-bottom: 22px;

  :hover {
    background-color: ${props => (props.varient === 'primary' ? '#18b5ca' : '#29C9DE')};
    color: ${props => (props.varient === 'primary' ? '#ffffff' : '#ffffff')};
  }
`;
export const EditLabelColorSelectTick = styled(LabelColorSelectTickSVG)`
  z-index: 5;
  margin-left: 39px;
  margin-top: 18px;
`;
