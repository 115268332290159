import styled from 'styled-components';
import { colors } from '../colors';

const InputWrapper = styled.div`
  border: 1px solid ${colors.inputColor};
  border-radius: 5px;
  padding: 10px 20px;
  width: 100%;
  background-color: #ffffff;

  input {
    border: none;
    font-size: 16px;
    outline: none;
    width: 100%;

    background-color: #ffffff;
  }
`;

export default InputWrapper;
